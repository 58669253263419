// Tokentags

.tokentags {
	background-color: white;
	flex: 1;
	position: relative;
	padding-left: 0.2rem;

	* {
		box-sizing: border-box;
		outline: 0;
	}

	&.max-width {
		max-width: 64rem;
	}

	.tokentags-list {
		display: flex;
		flex-flow: row wrap;
		padding: 0;
		margin: 0;
		list-style: none;

		.tokentags-input-container {
			margin: 0;
			flex: 1;
			min-width: 15rem;

			.tokentags-input {
				border: none;
				font-size: 1.5rem;
				height: 4.8rem;
				min-width: 15rem;
				max-width: 100%;
				padding: 0.6rem 1.2rem;
				width: 100%;
			}
		}

		&.error {
			box-shadow: 0 0 4px red;

			::-webkit-input-placeholder { /* WebKit, Blink, Edge */
				color: red;
			}

			:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
				color: red;
				opacity: 1;
			}

			::-moz-placeholder { /* Mozilla Firefox 19+ */
				color: red;
				opacity: 1;
			}

			:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: red;
			}
		}

		.tokentags-tag-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			height: 4rem;
			margin: 0.4rem 0.2rem 0 0.2rem;
			padding: 1.1rem 1.3rem;
			background-color: $gray2-lightest;
			border-radius: 3px;
			font-size: 1.4rem;

			.tokentags-tag-text {
				display: inline-block;
				font-size: $font-size-base-rem;
				max-width: 14rem;
				overflow: hidden;
				text-overflow: ellipsis;
				text-transform: uppercase;
				white-space: nowrap;
			}

			.tokentags-tag-remove {
				border: none;
				cursor: pointer;
				margin-left: 1rem;
				overflow: hidden;
				background: $caillou;
				border-radius: 50%;
				font-weight: 700;
				color: white;
				padding: 0.5rem 0.4rem;
				line-height: 0.375;
			}
		}
	}

	.tokentags-results {
		background-color: white;
		border: 1px solid #424242;
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		width: 100%;
		z-index: 10;
		max-height: 24rem;
		overflow-y: auto;

		.tokentags-results-item {
			color: $gray-p;
			cursor: pointer;
			padding: 1rem;
			font-size: 1.4rem;

			&.selected {
				background-color: $deepblue;
				color: white;
			}
		}
	}
}
