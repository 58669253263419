/*
* TOC
*
* RECHERCHE PAR REFERENCE
* OVERLAY
*/

@include selection(#FFF, $brand-primary);

@-webkit-keyframes fade-in-right {
	0% {
		-webkit-transform: translateX(50px);
		transform: translateX(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-in-right {
	0% {
		-webkit-transform: translateX(50px);
		transform: translateX(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.fade-in-right {
	-webkit-animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	-moz-animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.h1 {
	font-weight: $bold-font-weight;
	color: #fff;
	text-align: center;
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 36px;
	font-family: $font-family-sans-serif;

	@media screen and (max-width: $screen-sm-max) {
		margin: 1.9rem auto 0;
		width: 85%;
		font-size: 2rem;
	}
}

.h1-abonnement {
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	display: block;
	margin: 10rem 0 0;
	font-size: 3.6rem;
	line-height: 1.1;
	font-family: $font-family-sans-serif;
}

.non-abonnement {
	span.h1-abonnement {
		text-transform: none;
		color: black;
		margin-bottom: 21rem;
	}
}

.owl-stage {
	display: flex;
}

.owl-stage-outer {
	overflow: hidden;
}

.owl-carousel {
	overflow: hidden;

	.owl-wrapper {
		position: absolute;
	}
}

.search-error-container {
	font-size: 1.5rem;
	color: red;
	margin-top: 0.5rem;
}

.tokentags-tag-item {
	&:hover {
		cursor: pointer;
	}
}

.cnil-info,
.cnil-info-modif {
	margin-top: 1.6rem;
	font-size: 1.2rem;
	line-height: 1.6rem;
	text-align: left;
	color: $gray-subtitle;

	.contact-exercise-rights {
		cursor: pointer;
		text-decoration: underline;
		color: $gray-subtitle;
	}

	a {
		cursor: pointer;
		text-decoration: underline;
		color: $gray-subtitle;
	}
}

.mentions-legales {
	.title {
		font-size: 3.9rem;
		font-family: $font-family-sans-serif;
		font-weight: $bold-font-weight;
		line-height: 1.1;
		color: inherit;
		display: inline;
		margin-top: 1rem;
		text-align: center;
		display: block;
		margin-bottom: 0.3rem;

		h1 {
			font-size: $font-size-base-rem;
			display: inline;
		}
	}
}

.cgu {
	font-size: $font-size-base-rem;

	h1 {
		font-size: 1.8rem;
		font-weight: $bold-font-weight;
		text-align: center;
	}

	h2 {
		font-size: $font-size-base-rem;
		font-weight: $bold-font-weight;
		padding-top: 1.5rem;
	}

	h3 {
		font-size: 1.4rem;
		font-weight: $bold-font-weight;
	}

	a {
		word-break: break-all
	}

	table {
		table-layout: fixed;
		width: 100%;

		tr td {
			border-bottom: 1px solid black;
			vertical-align: top;
			padding: 0.4rem;
		}

		tr td:first-of-type {
			width: 12rem;
		}

		tr td ul {
			margin-top: 0;
		}
	}

	table:not(#table-tiers) {
		tbody tr td:first-of-type {
			font-weight: $bold-font-weight;
		}
	}

	@media screen and (min-width: $screen-md-min) {
		table {
			table-layout: auto;

			tr td:first-of-type {
				width: 12rem;
			}
		}

		#table-duration tr td:first-of-type {
			min-width: 40rem;
		}

		#table-tiers tr td:first-of-type {
			min-width: 67rem;
		}
	}
}

// > FORM
.table-form {
	display: table;
	width: 100%;
	margin-bottom: 0.8rem;

	.form-group {
		display: table-row;

		label {
			width: 30%;
		}

		input {
			margin: 0.8rem 0;
		}

		>* {
			display: table-cell;
		}

		.parsley-errors-list {
			display: block !important;
			margin-bottom: 0.8rem;
		}
	}
}

// >>>> toggle onmouseover permettant d'afficher le tooltip de conversions de prix en location
.conversion-toggle {
	width: 1.2rem;
	height: 1.3rem;
	line-height: 1;
	border-radius: 50%;
	border: 0;
	padding: 0;
	background-color: $brand-primary;
	cursor: pointer;
	color: #fff;
	font-size: 1.1rem;
}

// > OVERLAY
#overlay,
.overlay {
	width: 100%;
	height: 100%;
	background-color: #000;
	@include opacity(0);
	position: fixed;
	top: 0;
	left: 0;
	z-index: -8;
	@include transition(0s all linear);

	&.show {
		@include transition(.3s all linear);
		z-index: 10000;
		@include opacity(.5);
	}
}

// > Centered Loader
#centered-loader {
	opacity: 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -8;
	background: url("/Content/App/dist/img/user-interface/loader-ring.svg") center center no-repeat;
	background-size: 4rem 4rem;

	&.show {
		z-index: 10010;
		opacity: 1;
	}
}

// Hotjar behind the RGPD banner
#_hj_feedback_container {
	position: relative;
	z-index: 10005;
}

.modal.fade:not(.in).left .modal-dialog {
	-webkit-transform: translate3d(-25%, 0, 0);
	transform: translate3d(-25%, 0, 0);
}

.modal.fade:not(.in).right .modal-dialog {
	-webkit-transform: translate3d(25%, 0, 0);
	transform: translate3d(25%, 0, 0);
}

.modal.fade:not(.in).bottom .modal-dialog {
	-webkit-transform: translate3d(0, 25%, 0);
	transform: translate3d(0, 25%, 0);
}

.mentions-legales p {
	text-align: justify;
}

.sub-title {
	font-size: x-large;
	font-weight: lighter;
}

.btn span.fa,
.btn span.glyphicon {
	margin-right: 1rem;
}

/* makes the font 33% larger relative to the icon container */
.glyphicon-lg {
	font-size: 1.33333333rem;
	line-height: 1.2rem;
	vertical-align: -15%;
}

.glyphicon-2x {
	font-size: 3.2rem;
}

.glyphicon-3x {
	font-size: 4.8rem;
}

.glyphicon-4x {
	font-size: 6.4rem;
}

.glyphicon-5x {
	font-size: 8rem;
}

.glyphicon-fw {
	width: 2rem;
	text-align: center;
}

.light {
	font-weight: $light-font-weight;
}

.regular {
	font-weight: $regular-font-weight;
}

.medium-bold {
	font-weight: $medium-font-weight;
}

.bold {
	font-weight: $bold-font-weight;
}

// >> Bouton retour vers le haut
#back-to-top {
	right: 0;
	bottom: 60%; // instead of 50% because of hotjar btn

	&.affix-top {
		a {
			display: none;
		}
	}

	a {
		width: 5rem;
		height: 5rem;
		display: block;
		background: $gray-lightest;
		color: $brand-primary;
		text-align: center;

		span {
			line-height: 5rem;
			font-size: 1.9rem;
		}

		&:hover {
			color: $brand-primary-saturated;
		}
	}
}

iframe#listingVendeur {
	width: 100%;
	height: 125rem;
}

body.domain-edito-agorabiz-com {
	font-size: $font-size-base-rem;

	#header {
		.navbar {
			li a {
				font-size: 1.3rem;
				text-decoration: none;
				color: $brand-primary;
			}

			#mobile-main-menu .navbar-toggle {
				text-decoration: none;
			}
		}
	}

	#footer {
		a {
			text-decoration: none;
		}

		nav ul li a,
		#links-groupe_seloger ul li a {
			color: $brand-primary;
		}

		nav ul li a:hover,
		#links-groupe_seloger ul li a:hover {
			color: $brand-primary-saturated;
		}
	}
}

#adHabillage {
	position: absolute;
	z-index: 0;
	top: 8rem;
	width: 191.1rem;
	left: 50%;
	margin-left: -96rem;
	overflow: hidden;
}

span.modalTitle {
	color: $gray-dark !important;
	text-transform: uppercase;
	font-size: 1.9rem;
	width: 88%;
	margin-left: 2rem;
	display: block;
}

p.bg-danger {
	background-color: $brand-danger;
	padding: 1.5rem;
	color: #fff;

	&.contact-home {
		margin-top: 1rem;
	}
}

p.bg-success {
	background-color: $brand-success;
	padding: 1.5rem;
	color: #fff;

	&.contact-home {
		margin-top: 1rem;
	}
}

.nowrap {
	white-space: nowrap;
}

// Layout for AGB pagination
nav.agb-pagination {
	display: flex;
	justify-content: center;
}

// > Error pages (404/500)
div.error-page-wrapper {
	background: url('/Content/App/dist/img/visuals/error-page.jpg') no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	>div.error-page-content {
		color: #fff;
		text-align: center;
		height: 90rem;

		@media screen and (max-width: $screen-xs-max) {
			height: 60rem;
		}

		h1 {
			font-size: 30px;
			font-weight: $medium-font-weight;
		}

		h2 {
			font-weight: $regular-font-weight;
			margin-top: 1rem;
		}

		h1,
		.btn {
			margin-top: 3.5rem;

			&.btn {
				font-size: $font-size-base-rem;
			}
		}
	}
}

// > Breadcrumb hacks
.vertical-flex {
	@include flexbox;
	@include flex-direction(column);

	#breadcrumb {
		order: 1;
		/* pour afficher fil d'Ariane apres mentions #meta */
		padding: 0;
	}
}

// > Body container hack
.liste-annonces .body-wrapper.container {
	padding-left: 0;
	padding-right: 0;
	z-index: 1;
}

//Opp Preneur

#modal-cnil {
	display: none;
}

#opp-preneur {
	.actions .agb-button.opp-btn-success {
		width: auto;
		min-width: unset;
		color: #fff;
		background-color: $green;
		border-color: $green;

		&:focus,
		&:hover,
		&:active {
			color: #fff;
			background-color: $green !important;
			border-color: $green;
			box-shadow: none;
		}
	}

	.modal-header {
		height: 9.2rem;
		padding-top: 2.2rem;

		&.out-steps {
			height: auto;
		}
	}

	#error-opp-preneur-typetransaction {
		margin-top: 0.8rem;
		padding-left: 0;
	}

	.btn,
	span.modalTitle {
		text-transform: initial;
	}

	.title {
		text-align: center;
		margin-bottom: 0.6rem;
		font-size: 2.5rem;

		span {
			color: #5ea01f;
			font-weight: $bold-font-weight;
		}
	}

	.actions {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 1rem auto 0;
		padding: 0 1.6rem;

		@media screen and (min-width: $screen-md-min) {
			flex-direction: row;
		}

		a {
			@media screen and (max-width:$screen-xs-max) {
				margin-bottom: 1.6rem;
			}

			@media screen and (min-width: $screen-md-min) {
				min-width: 16.4rem;

				&:first-of-type {
					margin-right: 2rem;
				}
			}
		}

		.agb-btn-left {
			color: $brand-primary;
			background-color: transparent;
			border: 1px solid $brand-primary;
			transition: background-color 0.2s;

			@media screen and (max-width: $screen-sm-max) {
				margin-bottom: 1.6rem;
			}

			&:hover,
			&:focus {
				color: #ffffff;
				background-color: $orange-dark !important;
				border: none;

				.paper-dart-icon {
					background: url("/Content/App/dist/img/user-interface/paper-dart-White.svg") center center no-repeat;
				}
			}

			&.opp-btn-success,
			&.opp-btn-success:hover,
			&.opp-btn-success:focus {
				.paper-dart-icon {
					background: url("/Content/App/dist/img/user-interface/paper-dart-White.svg") center center no-repeat;
				}
			}

			.paper-dart-icon {
				display: block;
				width: 2rem;
				height: 2rem;
				margin-right: 0.8rem;
				background: url("/Content/App/dist/img/user-interface/paper-dart-orange.svg") center center no-repeat;
			}
		}

		.agb-btn-right {
			color: #ffffff;
			background-color: $brand-primary;
			transition: background-color 0.2s;
		}
	}

	#steps {
		text-align: center;
		margin-bottom: 1rem;

		.step {
			width: 10.7rem;

			&.fullwidth {
				width: 60%;
			}

			height: 0.6rem;

			@media screen and (max-width: $screen-xs-max) {
				width: 8rem;
			}

			border-radius: 2px;
			margin-right: 1.2rem;
			background-color: #dddede;
			display: inline-block;

			&.active {
				background-color: #5ea01f;
			}
		}
	}

	.modal-body {
		background-color: #fff;
	}

	#content {
		overflow: hidden;
		position: relative;
	}

	.stepBlock {
		display: none;

		&.active {
			display: block;
		}

		.tokentags-input {
			height: 4.4rem;
		}

		.tokentags-tag-item {
			padding: 0.9rem 1.3rem;
			font-size: 1.1rem;
			height: 3.6rem;
			font-size: $font-size-base-rem;
		}

		.cnil-info {
			font-size: 1.2rem;
		}
	}
}


label.required::after {
	content: "*";
	color: red;
	margin-left: 0.4rem;
}

input[type=radio] {
	display: none;
}

.customRadioButton {
	border: 1px solid $gray2;
	color: black;
	margin: 0;
	padding: 0;
	height: 5.3rem;
	text-align: center;
	padding-top: 1.3rem;
	font-size: $font-size-base-rem;
	float: left;
	height: 48px;

	@media screen and (max-width: $screen-xs-max) {
		padding-top: 1.5rem;
		font-size: 1.2rem;
		border-left: 1px solid $gray2;
	}

	&:hover {
		cursor: pointer;
		background: #f3f3f3;
	}

	&.active {
		color: white;
		border: 1px solid $green-lighter;
		background: $green-lighter;
	}
}

.civiliteBlock {
	.customRadioButton:first-of-type {
		border-right: 1px solid $gray2;

		&.active {
			border-right: none;
		}
	}
}

#oppPreneur .form-group {
	float: left;
	width: 100%;
}

#oppPreneur {
	padding: 0 2rem;
}

#opp-preneur {

	.stepBlock {

		&.last,
		&.first {
			width: 100%;
		}

		&.last,
		&.first {
			text-align: center;
		}

		&.last .action-title,
		&.first .action-title {
			text-align: center;
			font-size: 3.6rem;
			margin-bottom: 1rem;
			margin-top: 1.5rem;
			line-height: normal;
		}
	}

	#contact-opportunite-commentaire {
		padding-top: 12px;
	}

	#terminer {
		color: grey;
		margin-top: 2rem;
		margin-bottom: 1.5rem;
		text-decoration: underline;
		display: block;
	}

	.localites-tags-unique {
		border: 1px solid #ccc;
	}

	.stepBlock.first ul {
		list-style-type: none;

		@media screen and (max-width: $screen-xs-max) {
			width: 100%;
			padding: 0;
		}

		text-align: left;
		margin: auto;
		margin-bottom: 4.5rem;

		li:before {
			content: "\25a0";
			font-size: 2rem;
			display: inline-block;
			vertical-align: text-bottom;
			margin-right: 1rem;
			vertical-align: 0.2rem;
		}
	}

	ul li {
		font-size: 1.9rem;
	}

	#error-message-container,
	#error-message-container-optimiser,
	#error-message-container-mail {
		width: 60%;
		margin: 2rem auto 0;
		color: red;
		font-size: 1.5rem;
		text-align: center;
	}
}

#continue-button {
	display: none;
}

.no-float {
	float: none;
}

.stepBlock {
	p {
		margin-top: 2rem;
		font-size: 2.1rem;
	}

	&.last p.strong {
		margin-bottom: 2rem;
	}

	&.last p.souhaiterVous {
		width: 45rem;
		margin: 1.5rem auto;
	}

	&.first p {
		font-size: 2rem;
		margin-top: 0.5rem;
		margin-bottom: 3.5rem;
	}
}

.strong {
	font-weight: $bold-font-weight;
}

#start-opportunite-form {
	width: 12.4rem;
	margin: 0 auto 3rem;
}

#continue-button button {
	width: 12.4rem;
	margin: 3rem auto 3.8rem;
}

#opp-preneur {
	.out-steps {
		background: #fff;

		span,
		#steps {
			display: none;
		}
	}

	.parsley-errors-list li {
		color: $state-error-text;
	}

	.parsley-error {
		border-color: red;
		color: #6E6C6C;
		margin-bottom: 1rem;

		input::-webkit-input-placeholder {
			font-weight: $bold-font-weight;
			color: red;
		}

		input:-moz-placeholder {
			/* Firefox 18- */
			font-weight: $bold-font-weight;
			color: red;
		}

		input::-moz-placeholder {
			/* Firefox 19+ */
			font-weight: $bold-font-weight;
			color: red;
		}

		input:-ms-input-placeholder {
			font-weight: $bold-font-weight;
			color: red;
		}
	}
}

#handshakeImage {
	width: 100%;
	height: 7rem;
	background: url("/Content/App/dist/img/visuals/handshake.jpg") no-repeat center;
}

#flyingEnvelope {
	width: 100%;
	height: 9rem;
	background: url("/Content/App/dist/img/visuals/flyingenvelope.jpg") no-repeat center;
}

.localites-tags {
	margin-right: 5.6rem;
}

.search-localite {
	.input-group-btn {
		right: 4.6rem;
		position: absolute;
		top: 0;
	}

	.localite-tags {
		margin-right: 4.6rem;
	}
}

.error-validator-container {
	color: red;
	margin-top: 0.5rem;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-left {
	margin-left: 0 !important;
}

.no-margin-right {
	margin-right: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}


input::-webkit-input-placeholder {
	color: $gray2;
}

input::-moz-placeholder {
	color: $gray2;
}

input:-ms-input-placeholder {
	color: $gray2;
}

input:-moz-placeholder {
	color: $gray2;
}

// desactive l'icone dans les champs input (Safari)
input::-webkit-contacts-auto-fill-button {
	visibility: hidden;
	display: none !important;
	pointer-events: none;
	position: absolute;
	right: 0;
}

.flex-horizontal-center {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.radio-btn {
		flex: 1 1 auto;
	}
}

.flex-vertical-direction {
	display: flex;
	flex-direction: column;
	justify-content: center;
}