// changement caroursel page détails
.detail{
    .slider-wrapper{
        .col-xs-1.control-wrapper,
	    .col-sm-1.control-wrapper {
		    width: 100%;
	    }
    }    
}

.slider-wrapper {
	// contrôles de slider
	.control-wrapper {
		text-align: center;
		min-height: 28rem;

		.controls {
			position: absolute;
			top: 50%;
			margin: -2rem auto 0;

			&.prev {
				left: -1rem;
			}

			&.next {
				right: -1rem;
			}

			&:hover {
				color: $brand-primary-saturated;
			}

			.fa {
				font-size: 4rem;

				@media (max-width: $screen-sm-max) {
					// font-size: 6.4rem;
				}
			}
		}
	}

	.owl-dots {
		text-align: center;
		font-size: 1.9rem;
		color: $brand-primary;
	}

	.owl-dot {
		@extend .fa;
		@extend .fa-circle-thin;
		height: 3rem;
		width: 2.5rem;
		display: inline-block;
		margin: 2rem 0.5rem;
		line-height: 3rem;
		vertical-align: middle;

		&:hover {
			color: $brand-primary-saturated;
			@extend .fa-circle;
		}

		&.active {
			@extend .fa-circle;
			font-size: 2.4rem;
			color: $brand-primary-light;
		}

		@media (max-width: $screen-sm-max) {
			margin: 2rem 0;
		}
	}

	.owl-item > a {
		display: block;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	.col-xs-1.control-wrapper,
	.col-sm-1.control-wrapper {
		width: 3%;
		padding: 0;
	}



	.col-xs-10.slider,
	.col-sm-10.slider {
		width: 94%;
	}

	@media screen and (max-width: $screen-sm-max) {
		.col-xs-10.slider,
		.col-sm-10.slider {
			width: 84%;
		}

		.col-xs-1.control-wrapper,
		.col-sm-1.control-wrapper {
			width: 8%;
			padding: 0;
		}
	}
}

.scrollbar {
	position: absolute;
	width: 0.3rem;
	height: 60rem;
	background: #E5E5E5 none repeat scroll 0% 0%;
	line-height: 0;
}
.scrollbar .handle {
	width: 100%;
	height: 10rem;
	background: #A6A6A6 none repeat scroll 0% 0%;
	cursor: pointer;
}
