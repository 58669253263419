// Normalize non-controls
label {
    font-weight: normal;
}

// Common form controls
.form-control {
    font-size: $font-size-base + 1;
    @include box-shadow(none);
	resize: none;
}

// Form groups
.form-group {
	&.is-required {
		> label:after {
			content: " *";
			font-weight: $bold-font-weight;
			color: $brand-primary;
		}
	}
}

// Form control sizing
@include input-size('.input-sm', $input-height-small, $padding-small-horizontal, $padding-small-vertical, $font-size-base, $line-height-small, $border-radius-small);
@include input-size('.input-lg', $input-height-large, $padding-large-horizontal, $padding-large-vertical, $font-size-large, $line-height-large, $border-radius-large);

// Warning
.has-warning {
    @include form-control-validation($state-warning-text, $state-warning-border, $state-warning-bg);
}
// Error
.has-error,
.parsley-error {
    @include form-control-validation($state-danger-text, $state-danger-border, $state-danger-bg);
    @include placeholder($state-danger-border);
	font-weight: 600;
}
// Success
.has-success,
.parsley-success {
    @include form-control-validation($state-success-text, $state-success-border, $state-success-bg);
}
