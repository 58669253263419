// Contact Annonce Body
#contact-annonceur-body {
	margin-top: 5rem;
	background-color: $gray-lightest;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;

	@media screen and (max-width: $screen-sm-min) {
		display: none;
	}
	// Send
	.btn.btn-primary.btn-block {
		line-height: 1.6rem;
		padding: 0.8rem 1.6rem;

		> .glyphicon {
			margin-right: 1.6rem;
		}
	}

	.form-group {
		margin-bottom: 1.4rem;

		input.form-control {
			height: 4rem;
		}
	}
	// Header
	.header-contact-body {
		.title {
			font-size: 2.2rem;
			font-weight: $bold-font-weight;
		}

		.btn-telephone {
			padding-top: 0.5rem;
		}
	}

	.tel {
		font-size: $font-size-base-rem;
		cursor: pointer;
	}

	p.mandataire-tel-target {
		color: $brand-primary;
		font-size: $font-size-base-rem;
		margin-bottom: 0;
	}
	// Body content
	.content-contact-body {
		margin-top: 0.5rem;

		textarea {
			height: 14rem;
		}

		.checkbox + .checkbox {
			margin-top: 1rem;
		}

		.btn-envoyer {
			height: 4rem;
		}

		.contact-fields {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		.contact-options {
			margin-bottom: 1.5rem;
		}

		.lien-cgu {
			clear: both;
			display: block;
			text-align: center;
			margin-top: 1.6rem;
			font-size: $font-size-small;

			a {
				color: $gray-light;

				&:hover {
					color: $dark-grey;
				}
			}
		}
	}

	.bandeau-mandataire {
		@include flexbox;
		@include flex-direction(column);
		background-color: white;
		height: 37rem;
		justify-content: space-around;
		padding: 1rem;
		text-align: center;
		width: 100%;

		@media screen and (max-width: $screen-xs-max) {
			> div {
				margin: auto !important;
			}
		}

		.tel {
			color: $brand-primary;
			font-weight: $bold-font-weight;
			font-size: 2.2rem;

			@media screen and (max-width: $screen-xs-max) {
				margin-bottom: 1rem;
			}
		}

		.mandat-logo {
			margin: 0 auto;

			& + .mandataire-content {
				margin-top: 1rem;
				padding: 1rem 0;

				@media screen and (max-width: $screen-xs-max) {
					margin-top: auto;
				}
			}
		}

		.mandataire-content {
			.mandataire-name {
				font-weight: $bold-font-weight;
				font-size: 1.5rem;
			}
		}

		@media screen and (max-width: $screen-xs-max) {
			height: auto;
			margin-bottom: 2rem;

			> div {
				@include flexbox;
				margin: 0;
				text-align: left;
			}

			.mandataire-content {
				margin: auto 1rem;
			}
		}
	}

	a.smallLink {
		display: block;
		font-size: 1.3rem;
		margin-top: 1.6rem;
		text-transform: uppercase;
	}
}
// Generic contact sidebar
/*.contact-sidebar{*/
.recapitulatif-contact {

	.succes-envoi-demande-contact {
		padding: 1.5rem;
		text-align: center;
		line-height: 2.5rem;
		color: $green;
		margin-top: 1rem;

		.fa-success {
			font-size: 8rem;
			display: block;
		}

		.message-title {
			font-size: 2.4rem;
			color: $green;
			font-weight: $bold-font-weight;
		}

		.societe-target {
			font-weight: $bold-font-weight;
		}
	}

	.criteres {
		background-color: #ddeadd;
		padding: 1.5rem 2rem 2rem 2rem;

		.rappel-demande {
			margin-bottom: 1rem;
		}

		.recap-titre {
			font-size: 1.7rem;
			font-weight: $bold-font-weight;
			margin-bottom: 0;
		}
	}
}
.message-alerte {
	color: $green;
	text-align: center;
	font-size: $font-size-base-rem;
	font-weight: $bold-font-weight;
	display: block;
	margin: 2.5rem 0;
}
/*}*/
// Contact Annonce SideBar
#contact-annonceur {
	&.transition {
		transition-property: margin-top;
		transition-duration: 1s;
		transition-delay: 0.17s;
	}

	&.tel-active {
		margin-top: 2rem;
	}

	.tel {
		padding-top: 1rem;
		padding-bottom: 0;
	}

	&.contact-mandataire, .contact-mandataire {
		background-color: $gray-lightest;
		padding-top: 0;
		padding-bottom: 2rem;
		position: relative;
		left: 0;

		.checkbox {
			padding-left: 0;
		}

		.recapitulatif-contact {
			margin-bottom: 1.5rem;
		}

		.col-2-form {
			width: 49.3%;
			padding-right: 2.4%;
			display: inline-block;
			vertical-align: top;

			& + .col-2-form {
				padding-right: 0;
				padding-left: 2.4%;
			}
		}

		.form-group {
			margin-bottom: 0;

			.form-control {
				height: 3.5rem;
				margin-bottom: 1rem;
			}

			textarea.form-control {
				resize: none;
				height: 9rem;
				display: inline-block;
			}
		}

		.btn-envoyer {
			padding: 0.8rem 1.6rem;
		}

		.contact-nom {
			font-size: 1.9rem;
			margin-top: 2.5rem;
			margin-bottom: 1rem;
		}

		.btn-envoyer {
			margin-bottom: 1.5rem;
		}

		.lien-cgu {
			display: block;
			text-align: center;
			font-size: $font-size-small;

			a {
				color: $gray-light;

				&:hover {
					color: $dark-grey;
				}
			}
		}

		.nav-tabs {
			margin: 0 -1.5rem;

			li {
				width: 50%;
				background-color: $brand-primary;

				&.active {
					background: url(../img/user-interface/chevron-active-contact.png) $brand-primary-saturated bottom center no-repeat;
				}

				a {
					border: none;
					text-align: center;
					color: white;
					font-weight: $bold-font-weight;
					text-transform: uppercase;
					margin-right: 0;
					background: none;
				}
			}
		}

		.tab-pane {
			.tel {
				color: $brand-primary;
				font-weight: $bold-font-weight;
				font-size: 2.4rem;
				padding-bottom: 1rem;
				text-align: center;
			}

			.tel-msg {
				padding-bottom: 1rem;
				font-size: $font-size-base-rem;
			}
		}

		.mandataire-coordonnees {
			height: auto;
			display: block;
		}

		hr {
			margin-bottom: 0.5rem;
			margin-top: 1.5rem;
		}
		// > Treatments for contact form that contains coex
		&.with-coex {
			.checkbox {
				& + .checkbox {
					margin-top: 1rem;
				}
			}
		}

		.coex-container {
			margin-top: 2.5rem;
		}
	}

	.contact-mandataire {
		padding-top: 2rem;
	}

	&.modal {
		.modal-header {
			padding-bottom: 0;
		}

		.contact-mandataire {
			padding-top: 0;
		}

		.mandataire-coordonnees {
			height: auto !important;
		}
	}
}

// >>> Contact Telephone (mandataire)
#contact-telephone {
	.mandataire-coordonnees {
		height: 8.5rem;

		@media screen and (max-width: $screen-xs-max) {
			height: 7.5rem;
		}
	}

	.tel {
		font-size: 2.2rem;
		text-align: center;
		margin: 2rem auto;
	}

	.tel-mobile {
		width: 100%;
		margin: 2rem 0;
		font-weight: $bold-font-weight;
		font-size: 2rem;
		white-space: normal;

		@media screen and (max-width: $screen-sm-max) {
			width: auto;
			margin-top: 1.5rem;
		}
	}

	@media screen and (max-width: $screen-xs-max) {
		.logo-part {
			margin-left: 1.5rem;

			&.hidden {
				display: block !important;
			}
		}
	}
}

// Contact Annonce list Popup

// > New modal Generic components
#contact-annonceur, .modal {
	.mandataire-coordonnees {
		height: 13rem;

		@media screen and (max-width: $screen-xs-max) {
			height: 10rem;
		}

		.mandataire-nom {
			font-weight: $bold-font-weight;
		}
	}

	&.modal {
		.modal-header {
			padding-bottom: 0;
		}

		.contact-mandataire {
			padding-top: 0;
		}
	}
}

// >>>> Formulaire Co-Exclusivite
.coex-container {
	margin-top: 1.5rem;

	.coex-item {
		margin-bottom: 1.5rem;

		&:last-child {
			margin-bottom: 0;
		}

		.col-chk {
			width: 14%;
		}

		.checkbox {
			margin-top: 0;
			padding-left: 0;
		}

		.coex-content {
			width: 81%;

			> .coex-infos {
				@include flexbox;
			}

			.mandataire-content {
				font-size: $font-size-base-rem;
				margin-left: 1rem;

				span.mandataire-name {
					font-weight: $bold-font-weight;
				}

				.mandataire-adresse {
					display: block;
				}
			}

			.coex-links {
				margin-top: 0.5rem;

				a.smallLink, .tel {
					font-size: $font-size-small !important;
					margin-top: 0 !important;
					text-transform: none !important;
				}

				.lnk-website {
					float: left;
				}

				.tel {
					float: right;
				}
			}
		}
	}
	// > Formulaire ventre de page (expanded view)
	&.coex-bordered {
		border-right: solid 2px $gray-light;
		//.coex-item {
		//	.coex-content {
		//		background-color: #fff;
		//		border: 1px solid $gray-light;
		//		.mandat-logo { margin-top: 10px; }
		//		.mandataire-content { margin-top: 10px; }
		//	}
		//}
	}
}

// > Common parts
.modal-footer {
	border-top: none;
	padding-top: 0;
	text-align: center;

	.btn.btn-primary {
		line-height: 1.6rem;
		padding: 0.8rem 1.6rem;

		> .glyphicon {
			margin-right: 1.6rem;
		}
	}

	span.lien-cgu {
		clear: both;
		display: block;
		text-align: center;
		margin-top: 1.6rem;
		font-size: $font-size-small;

		> a {
			color: $gray-light;

			&:hover {
				color: $dark-grey;
			}
		}
	}
}

.recapitulatif-contact {
	.recap-titre {
		display: block;
		font-size: 1rem;
		margin-bottom: 1rem;
	}

	.message-contact {
		white-space: pre-wrap;
		color: black;
		margin-bottom: 1rem;
	}

	.nom-contact,
	.tel-contact,
	.mail-contact,
	.societe-contact {
		color: $gray;
	}

	.button-continuer {
		margin-top: 3.2rem;
	}
}

// Logo mandataire
.mandat-logo {
	@include flexbox;
	align-items: center;
	background-color: #fff;
	border: 1px solid $gray-lighter;
	display: flex;
	height: 5rem;
	justify-content: center;
	min-width: 5rem;
	width: 5rem;

	&.medium {
		height: 8rem;
		min-width: 8rem;
		width: 8rem;

		> img {
			max-height: 7rem;
			max-width: 7rem;
		}
	}

	&.large {
		height: 9rem;
		min-width: 9rem;
		width: 9rem;

		> img {
			max-height: 8rem;
			max-width: 8rem;
		}

		@media screen and (max-width: $screen-xs-max) {
			height: 9rem;
			min-width: 9rem;
			width: 9rem;

			> img {
				max-height: 8rem;
				max-width: 8rem;
			}
		}
	}

	> img {
		max-height: 4rem;
		max-width: 4rem;
	}
}

// > Specific rules for Coex modal
.modal {
	#contact-annonceur-body {
		margin-top: 0;
		padding-bottom: 0;
		padding-top: 0;

		@media screen and (max-width: $screen-sm-min) {
			display: block;
		}

		.coex-container.coex-bordered {
			border-right: none;
			margin-top: 0;
		}

		.form-container {
			border-left: solid $gray-lighter 1px;

			&.no-border {
				border-left: none;
				margin-bottom: 0;
				padding: 0;
			}

			@media screen and (max-width: $screen-sm-min) {
				border-left: none;
				margin-bottom: 0;
				padding: 0;
			}

			.content-contact-body {
				margin-top: 0;
			}

			p.tel-msg {
				color: $brand-primary;
				font-size: 2.2rem;
				font-weight: $light-font-weight;
				margin-bottom: 1.5rem;

				span:first-child {
					font-weight: $bold-font-weight;
				}
			}
		}

		.coex-links {
			display: none;
		}

		.contact-typesbiens {
			div.checkbox {
				margin-bottom: 0.5rem;
				margin-top: 0;

				&:first-child {
					@extend .col-xs-7;
					white-space: nowrap;
				}

				&:last-child {
					@extend .col-xs-5;
				}
			}
		}

		.contact-fields > .col-sm-6 {
			@extend .col-sm-12;
		}

		.contact-options {
			margin-bottom: 0;

			> div.checkbox {
				@extend .col-sm-12;
				margin-top: 0;
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.content-contact-body {
			textarea {
				height: 100px;
			}
		}
		// > Special treatments for type "annuaire"
		&.type-annuaire {
			.content-contact-body .contact-fields {
				margin-top: 0;
			}

			.bandeau-mandataire {
				height: 30.5rem;

				@media screen and (max-width: $screen-xs-max) {
					height: auto;
				}
			}
		}
	}

	#tel-annonceur-body {
		.coex-container {
			margin-top: 0;

			.coex-item {
				@media screen and (max-width: $screen-xs-max) {
					border-bottom: solid $gray-lighter 1px;
					padding-bottom: 1rem;
				}

				.coex-content {
					width: 100%;
				}
			}
		}
	}
}
