// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-ajax-loader-name: 'sprite_ajax-loader';
$sprite-ajax-loader-x: 0px;
$sprite-ajax-loader-y: 130px;
$sprite-ajax-loader-offset-x: 0px;
$sprite-ajax-loader-offset-y: -130px;
$sprite-ajax-loader-width: 32px;
$sprite-ajax-loader-height: 32px;
$sprite-ajax-loader-total-width: 267px;
$sprite-ajax-loader-total-height: 231px;
$sprite-ajax-loader-image: '../img/user-interface-sprite.png';
$sprite-ajax-loader: (0px, 130px, 0px, -130px, 32px, 32px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_ajax-loader', );
$sprite-alerte-big-name: 'sprite_alerte-big';
$sprite-alerte-big-x: 194px;
$sprite-alerte-big-y: 130px;
$sprite-alerte-big-offset-x: -194px;
$sprite-alerte-big-offset-y: -130px;
$sprite-alerte-big-width: 27px;
$sprite-alerte-big-height: 27px;
$sprite-alerte-big-total-width: 267px;
$sprite-alerte-big-total-height: 231px;
$sprite-alerte-big-image: '../img/user-interface-sprite.png';
$sprite-alerte-big: (194px, 130px, -194px, -130px, 27px, 27px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_alerte-big', );
$sprite-app-store-button-name: 'sprite_app_store-button';
$sprite-app-store-button-x: 0px;
$sprite-app-store-button-y: 78px;
$sprite-app-store-button-offset-x: 0px;
$sprite-app-store-button-offset-y: -78px;
$sprite-app-store-button-width: 154px;
$sprite-app-store-button-height: 50px;
$sprite-app-store-button-total-width: 267px;
$sprite-app-store-button-total-height: 231px;
$sprite-app-store-button-image: '../img/user-interface-sprite.png';
$sprite-app-store-button: (0px, 78px, 0px, -78px, 154px, 50px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_app_store-button', );
$sprite-button-close-name: 'sprite_button-close';
$sprite-button-close-x: 134px;
$sprite-button-close-y: 130px;
$sprite-button-close-offset-x: -134px;
$sprite-button-close-offset-y: -130px;
$sprite-button-close-width: 28px;
$sprite-button-close-height: 28px;
$sprite-button-close-total-width: 267px;
$sprite-button-close-total-height: 231px;
$sprite-button-close-image: '../img/user-interface-sprite.png';
$sprite-button-close: (134px, 130px, -134px, -130px, 28px, 28px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_button-close', );
$sprite-button-cross-active-name: 'sprite_button-cross_active';
$sprite-button-cross-active-x: 80px;
$sprite-button-cross-active-y: 185px;
$sprite-button-cross-active-offset-x: -80px;
$sprite-button-cross-active-offset-y: -185px;
$sprite-button-cross-active-width: 14px;
$sprite-button-cross-active-height: 14px;
$sprite-button-cross-active-total-width: 267px;
$sprite-button-cross-active-total-height: 231px;
$sprite-button-cross-active-image: '../img/user-interface-sprite.png';
$sprite-button-cross-active: (80px, 185px, -80px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_button-cross_active', );
$sprite-button-cross-default-name: 'sprite_button-cross_default';
$sprite-button-cross-default-x: 128px;
$sprite-button-cross-default-y: 185px;
$sprite-button-cross-default-offset-x: -128px;
$sprite-button-cross-default-offset-y: -185px;
$sprite-button-cross-default-width: 14px;
$sprite-button-cross-default-height: 14px;
$sprite-button-cross-default-total-width: 267px;
$sprite-button-cross-default-total-height: 231px;
$sprite-button-cross-default-image: '../img/user-interface-sprite.png';
$sprite-button-cross-default: (128px, 185px, -128px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_button-cross_default', );
$sprite-button-cross-light-name: 'sprite_button-cross_light';
$sprite-button-cross-light-x: 96px;
$sprite-button-cross-light-y: 185px;
$sprite-button-cross-light-offset-x: -96px;
$sprite-button-cross-light-offset-y: -185px;
$sprite-button-cross-light-width: 14px;
$sprite-button-cross-light-height: 14px;
$sprite-button-cross-light-total-width: 267px;
$sprite-button-cross-light-total-height: 231px;
$sprite-button-cross-light-image: '../img/user-interface-sprite.png';
$sprite-button-cross-light: (96px, 185px, -96px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_button-cross_light', );
$sprite-check-active-name: 'sprite_check_active';
$sprite-check-active-x: 48px;
$sprite-check-active-y: 164px;
$sprite-check-active-offset-x: -48px;
$sprite-check-active-offset-y: -164px;
$sprite-check-active-width: 19px;
$sprite-check-active-height: 19px;
$sprite-check-active-total-width: 267px;
$sprite-check-active-total-height: 231px;
$sprite-check-active-image: '../img/user-interface-sprite.png';
$sprite-check-active: (48px, 164px, -48px, -164px, 19px, 19px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_check_active', );
$sprite-check-active-2-name: 'sprite_check_active_2';
$sprite-check-active-2-x: 200px;
$sprite-check-active-2-y: 78px;
$sprite-check-active-2-offset-x: -200px;
$sprite-check-active-2-offset-y: -78px;
$sprite-check-active-2-width: 42px;
$sprite-check-active-2-height: 42px;
$sprite-check-active-2-total-width: 267px;
$sprite-check-active-2-total-height: 231px;
$sprite-check-active-2-image: '../img/user-interface-sprite.png';
$sprite-check-active-2: (200px, 78px, -200px, -78px, 42px, 42px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_check_active_2', );
$sprite-check-default-name: 'sprite_check_default';
$sprite-check-default-x: 238px;
$sprite-check-default-y: 52px;
$sprite-check-default-offset-x: -238px;
$sprite-check-default-offset-y: -52px;
$sprite-check-default-width: 19px;
$sprite-check-default-height: 19px;
$sprite-check-default-total-width: 267px;
$sprite-check-default-total-height: 231px;
$sprite-check-default-image: '../img/user-interface-sprite.png';
$sprite-check-default: (238px, 52px, -238px, -52px, 19px, 19px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_check_default', );
$sprite-check-default-2-name: 'sprite_check_default_2';
$sprite-check-default-2-x: 156px;
$sprite-check-default-2-y: 78px;
$sprite-check-default-2-offset-x: -156px;
$sprite-check-default-2-offset-y: -78px;
$sprite-check-default-2-width: 42px;
$sprite-check-default-2-height: 42px;
$sprite-check-default-2-total-width: 267px;
$sprite-check-default-2-total-height: 231px;
$sprite-check-default-2-image: '../img/user-interface-sprite.png';
$sprite-check-default-2: (156px, 78px, -156px, -78px, 42px, 42px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_check_default_2', );
$sprite-chevron-active-contact-name: 'sprite_chevron-active-contact';
$sprite-chevron-active-contact-x: 252px;
$sprite-chevron-active-contact-y: 146px;
$sprite-chevron-active-contact-offset-x: -252px;
$sprite-chevron-active-contact-offset-y: -146px;
$sprite-chevron-active-contact-width: 15px;
$sprite-chevron-active-contact-height: 7px;
$sprite-chevron-active-contact-total-width: 267px;
$sprite-chevron-active-contact-total-height: 231px;
$sprite-chevron-active-contact-image: '../img/user-interface-sprite.png';
$sprite-chevron-active-contact: (252px, 146px, -252px, -146px, 15px, 7px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_chevron-active-contact', );
$sprite-close-grey-name: 'sprite_close-grey';
$sprite-close-grey-x: 125px;
$sprite-close-grey-y: 164px;
$sprite-close-grey-offset-x: -125px;
$sprite-close-grey-offset-y: -164px;
$sprite-close-grey-width: 16px;
$sprite-close-grey-height: 16px;
$sprite-close-grey-total-width: 267px;
$sprite-close-grey-total-height: 231px;
$sprite-close-grey-image: '../img/user-interface-sprite.png';
$sprite-close-grey: (125px, 164px, -125px, -164px, 16px, 16px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_close-grey', );
$sprite-close-grey2-name: 'sprite_close-grey2';
$sprite-close-grey2-x: 89px;
$sprite-close-grey2-y: 164px;
$sprite-close-grey2-offset-x: -89px;
$sprite-close-grey2-offset-y: -164px;
$sprite-close-grey2-width: 16px;
$sprite-close-grey2-height: 16px;
$sprite-close-grey2-total-width: 267px;
$sprite-close-grey2-total-height: 231px;
$sprite-close-grey2-image: '../img/user-interface-sprite.png';
$sprite-close-grey2: (89px, 164px, -89px, -164px, 16px, 16px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_close-grey2', );
$sprite-close-overlay-name: 'sprite_close-overlay';
$sprite-close-overlay-x: 34px;
$sprite-close-overlay-y: 130px;
$sprite-close-overlay-offset-x: -34px;
$sprite-close-overlay-offset-y: -130px;
$sprite-close-overlay-width: 32px;
$sprite-close-overlay-height: 32px;
$sprite-close-overlay-total-width: 267px;
$sprite-close-overlay-total-height: 231px;
$sprite-close-overlay-image: '../img/user-interface-sprite.png';
$sprite-close-overlay: (34px, 130px, -34px, -130px, 32px, 32px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_close-overlay', );
$sprite-close-white-name: 'sprite_close-white';
$sprite-close-white-x: 223px;
$sprite-close-white-y: 130px;
$sprite-close-white-offset-x: -223px;
$sprite-close-white-offset-y: -130px;
$sprite-close-white-width: 27px;
$sprite-close-white-height: 27px;
$sprite-close-white-total-width: 267px;
$sprite-close-white-total-height: 231px;
$sprite-close-white-image: '../img/user-interface-sprite.png';
$sprite-close-white: (223px, 130px, -223px, -130px, 27px, 27px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_close-white', );
$sprite-fav-active-name: 'sprite_fav_active';
$sprite-fav-active-x: 164px;
$sprite-fav-active-y: 130px;
$sprite-fav-active-offset-x: -164px;
$sprite-fav-active-offset-y: -130px;
$sprite-fav-active-width: 28px;
$sprite-fav-active-height: 28px;
$sprite-fav-active-total-width: 267px;
$sprite-fav-active-total-height: 231px;
$sprite-fav-active-image: '../img/user-interface-sprite.png';
$sprite-fav-active: (164px, 130px, -164px, -130px, 28px, 28px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_fav_active', );
$sprite-fav-default-name: 'sprite_fav_default';
$sprite-fav-default-x: 104px;
$sprite-fav-default-y: 130px;
$sprite-fav-default-offset-x: -104px;
$sprite-fav-default-offset-y: -130px;
$sprite-fav-default-width: 28px;
$sprite-fav-default-height: 28px;
$sprite-fav-default-total-width: 267px;
$sprite-fav-default-total-height: 231px;
$sprite-fav-default-image: '../img/user-interface-sprite.png';
$sprite-fav-default: (104px, 130px, -104px, -130px, 28px, 28px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_fav_default', );
$sprite-fleche-bas-gris-name: 'sprite_fleche-bas-gris';
$sprite-fleche-bas-gris-x: 69px;
$sprite-fleche-bas-gris-y: 164px;
$sprite-fleche-bas-gris-offset-x: -69px;
$sprite-fleche-bas-gris-offset-y: -164px;
$sprite-fleche-bas-gris-width: 18px;
$sprite-fleche-bas-gris-height: 18px;
$sprite-fleche-bas-gris-total-width: 267px;
$sprite-fleche-bas-gris-total-height: 231px;
$sprite-fleche-bas-gris-image: '../img/user-interface-sprite.png';
$sprite-fleche-bas-gris: (69px, 164px, -69px, -164px, 18px, 18px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_fleche-bas-gris', );
$sprite-google-play-button-name: 'sprite_google_play-button';
$sprite-google-play-button-x: 113px;
$sprite-google-play-button-y: 0px;
$sprite-google-play-button-offset-x: -113px;
$sprite-google-play-button-offset-y: 0px;
$sprite-google-play-button-width: 154px;
$sprite-google-play-button-height: 50px;
$sprite-google-play-button-total-width: 267px;
$sprite-google-play-button-total-height: 231px;
$sprite-google-play-button-image: '../img/user-interface-sprite.png';
$sprite-google-play-button: (113px, 0px, -113px, 0px, 154px, 50px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_google_play-button', );
$sprite-grabbing-name: 'sprite_grabbing';
$sprite-grabbing-x: 107px;
$sprite-grabbing-y: 164px;
$sprite-grabbing-offset-x: -107px;
$sprite-grabbing-offset-y: -164px;
$sprite-grabbing-width: 16px;
$sprite-grabbing-height: 16px;
$sprite-grabbing-total-width: 267px;
$sprite-grabbing-total-height: 231px;
$sprite-grabbing-image: '../img/user-interface-sprite.png';
$sprite-grabbing: (107px, 164px, -107px, -164px, 16px, 16px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_grabbing', );
$sprite-img-exclusivite-name: 'sprite_img-exclusivite';
$sprite-img-exclusivite-x: 0px;
$sprite-img-exclusivite-y: 0px;
$sprite-img-exclusivite-offset-x: 0px;
$sprite-img-exclusivite-offset-y: 0px;
$sprite-img-exclusivite-width: 111px;
$sprite-img-exclusivite-height: 76px;
$sprite-img-exclusivite-total-width: 267px;
$sprite-img-exclusivite-total-height: 231px;
$sprite-img-exclusivite-image: '../img/user-interface-sprite.png';
$sprite-img-exclusivite: (0px, 0px, 0px, 0px, 111px, 76px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_img-exclusivite', );
$sprite-img-photo-small-name: 'sprite_img-photo-small';
$sprite-img-photo-small-x: 213px;
$sprite-img-photo-small-y: 52px;
$sprite-img-photo-small-offset-x: -213px;
$sprite-img-photo-small-offset-y: -52px;
$sprite-img-photo-small-width: 23px;
$sprite-img-photo-small-height: 20px;
$sprite-img-photo-small-total-width: 267px;
$sprite-img-photo-small-total-height: 231px;
$sprite-img-photo-small-image: '../img/user-interface-sprite.png';
$sprite-img-photo-small: (213px, 52px, -213px, -52px, 23px, 20px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_img-photo-small', );
$sprite-img-photo-name: 'sprite_img-photo';
$sprite-img-photo-x: 68px;
$sprite-img-photo-y: 130px;
$sprite-img-photo-offset-x: -68px;
$sprite-img-photo-offset-y: -130px;
$sprite-img-photo-width: 34px;
$sprite-img-photo-height: 30px;
$sprite-img-photo-total-width: 267px;
$sprite-img-photo-total-height: 231px;
$sprite-img-photo-image: '../img/user-interface-sprite.png';
$sprite-img-photo: (68px, 130px, -68px, -130px, 34px, 30px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_img-photo', );
$sprite-information-name: 'sprite_information';
$sprite-information-x: 112px;
$sprite-information-y: 185px;
$sprite-information-offset-x: -112px;
$sprite-information-offset-y: -185px;
$sprite-information-width: 14px;
$sprite-information-height: 14px;
$sprite-information-total-width: 267px;
$sprite-information-total-height: 231px;
$sprite-information-image: '../img/user-interface-sprite.png';
$sprite-information: (112px, 185px, -112px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_information', );
$sprite-lead-call-w-name: 'sprite_lead-call-w';
$sprite-lead-call-w-x: 48px;
$sprite-lead-call-w-y: 185px;
$sprite-lead-call-w-offset-x: -48px;
$sprite-lead-call-w-offset-y: -185px;
$sprite-lead-call-w-width: 14px;
$sprite-lead-call-w-height: 14px;
$sprite-lead-call-w-total-width: 267px;
$sprite-lead-call-w-total-height: 231px;
$sprite-lead-call-w-image: '../img/user-interface-sprite.png';
$sprite-lead-call-w: (48px, 185px, -48px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_lead-call-w', );
$sprite-lead-call-name: 'sprite_lead-call';
$sprite-lead-call-x: 252px;
$sprite-lead-call-y: 130px;
$sprite-lead-call-offset-x: -252px;
$sprite-lead-call-offset-y: -130px;
$sprite-lead-call-width: 14px;
$sprite-lead-call-height: 14px;
$sprite-lead-call-total-width: 267px;
$sprite-lead-call-total-height: 231px;
$sprite-lead-call-image: '../img/user-interface-sprite.png';
$sprite-lead-call: (252px, 130px, -252px, -130px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_lead-call', );
$sprite-lead-mail-w-name: 'sprite_lead-mail-w';
$sprite-lead-mail-w-x: 64px;
$sprite-lead-mail-w-y: 185px;
$sprite-lead-mail-w-offset-x: -64px;
$sprite-lead-mail-w-offset-y: -185px;
$sprite-lead-mail-w-width: 14px;
$sprite-lead-mail-w-height: 14px;
$sprite-lead-mail-w-total-width: 267px;
$sprite-lead-mail-w-total-height: 231px;
$sprite-lead-mail-w-image: '../img/user-interface-sprite.png';
$sprite-lead-mail-w: (64px, 185px, -64px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_lead-mail-w', );
$sprite-lead-mail-name: 'sprite_lead-mail';
$sprite-lead-mail-x: 144px;
$sprite-lead-mail-y: 185px;
$sprite-lead-mail-offset-x: -144px;
$sprite-lead-mail-offset-y: -185px;
$sprite-lead-mail-width: 14px;
$sprite-lead-mail-height: 14px;
$sprite-lead-mail-total-width: 267px;
$sprite-lead-mail-total-height: 231px;
$sprite-lead-mail-image: '../img/user-interface-sprite.png';
$sprite-lead-mail: (144px, 185px, -144px, -185px, 14px, 14px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_lead-mail', );
$sprite-pin-name: 'sprite_pin';
$sprite-pin-x: 244px;
$sprite-pin-y: 78px;
$sprite-pin-offset-x: -244px;
$sprite-pin-offset-y: -78px;
$sprite-pin-width: 23px;
$sprite-pin-height: 35px;
$sprite-pin-total-width: 267px;
$sprite-pin-total-height: 231px;
$sprite-pin-image: '../img/user-interface-sprite.png';
$sprite-pin: (244px, 78px, -244px, -78px, 23px, 35px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_pin', );
$sprite-radio-active-name: 'sprite_radio_active';
$sprite-radio-active-x: 139px;
$sprite-radio-active-y: 52px;
$sprite-radio-active-offset-x: -139px;
$sprite-radio-active-offset-y: -52px;
$sprite-radio-active-width: 24px;
$sprite-radio-active-height: 24px;
$sprite-radio-active-total-width: 267px;
$sprite-radio-active-total-height: 231px;
$sprite-radio-active-image: '../img/user-interface-sprite.png';
$sprite-radio-active: (139px, 52px, -139px, -52px, 24px, 24px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_radio_active', );
$sprite-radio-default-name: 'sprite_radio_default';
$sprite-radio-default-x: 113px;
$sprite-radio-default-y: 52px;
$sprite-radio-default-offset-x: -113px;
$sprite-radio-default-offset-y: -52px;
$sprite-radio-default-width: 24px;
$sprite-radio-default-height: 24px;
$sprite-radio-default-total-width: 267px;
$sprite-radio-default-total-height: 231px;
$sprite-radio-default-image: '../img/user-interface-sprite.png';
$sprite-radio-default: (113px, 52px, -113px, -52px, 24px, 24px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_radio_default', );
$sprite-select-arrow-down-name: 'sprite_select-arrow_down';
$sprite-select-arrow-down-x: 164px;
$sprite-select-arrow-down-y: 164px;
$sprite-select-arrow-down-offset-x: -164px;
$sprite-select-arrow-down-offset-y: -164px;
$sprite-select-arrow-down-width: 19px;
$sprite-select-arrow-down-height: 12px;
$sprite-select-arrow-down-total-width: 267px;
$sprite-select-arrow-down-total-height: 231px;
$sprite-select-arrow-down-image: '../img/user-interface-sprite.png';
$sprite-select-arrow-down: (164px, 164px, -164px, -164px, 19px, 12px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_select-arrow_down', );
$sprite-select-arrow-right-name: 'sprite_select-arrow_right';
$sprite-select-arrow-right-x: 0px;
$sprite-select-arrow-right-y: 214px;
$sprite-select-arrow-right-offset-x: 0px;
$sprite-select-arrow-right-offset-y: -214px;
$sprite-select-arrow-right-width: 10px;
$sprite-select-arrow-right-height: 17px;
$sprite-select-arrow-right-total-width: 267px;
$sprite-select-arrow-right-total-height: 231px;
$sprite-select-arrow-right-image: '../img/user-interface-sprite.png';
$sprite-select-arrow-right: (0px, 214px, 0px, -214px, 10px, 17px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_select-arrow_right', );
$sprite-select-arrow-up-name: 'sprite_select-arrow_up';
$sprite-select-arrow-up-x: 143px;
$sprite-select-arrow-up-y: 164px;
$sprite-select-arrow-up-offset-x: -143px;
$sprite-select-arrow-up-offset-y: -164px;
$sprite-select-arrow-up-width: 19px;
$sprite-select-arrow-up-height: 12px;
$sprite-select-arrow-up-total-width: 267px;
$sprite-select-arrow-up-total-height: 231px;
$sprite-select-arrow-up-image: '../img/user-interface-sprite.png';
$sprite-select-arrow-up: (143px, 164px, -143px, -164px, 19px, 12px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_select-arrow_up', );
$sprite-slider-range-name: 'sprite_slider-range';
$sprite-slider-range-x: 189px;
$sprite-slider-range-y: 52px;
$sprite-slider-range-offset-x: -189px;
$sprite-slider-range-offset-y: -52px;
$sprite-slider-range-width: 22px;
$sprite-slider-range-height: 22px;
$sprite-slider-range-total-width: 267px;
$sprite-slider-range-total-height: 231px;
$sprite-slider-range-image: '../img/user-interface-sprite.png';
$sprite-slider-range: (189px, 52px, -189px, -52px, 22px, 22px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_slider-range', );
$sprite-slider-range-active-name: 'sprite_slider-range_active';
$sprite-slider-range-active-x: 165px;
$sprite-slider-range-active-y: 52px;
$sprite-slider-range-active-offset-x: -165px;
$sprite-slider-range-active-offset-y: -52px;
$sprite-slider-range-active-width: 22px;
$sprite-slider-range-active-height: 22px;
$sprite-slider-range-active-total-width: 267px;
$sprite-slider-range-active-total-height: 231px;
$sprite-slider-range-active-image: '../img/user-interface-sprite.png';
$sprite-slider-range-active: (165px, 52px, -165px, -52px, 22px, 22px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_slider-range_active', );
$sprite-text-range-hover1-name: 'sprite_text-range-hover1';
$sprite-text-range-hover1-x: 0px;
$sprite-text-range-hover1-y: 164px;
$sprite-text-range-hover1-offset-x: 0px;
$sprite-text-range-hover1-offset-y: -164px;
$sprite-text-range-hover1-width: 14px;
$sprite-text-range-hover1-height: 48px;
$sprite-text-range-hover1-total-width: 267px;
$sprite-text-range-hover1-total-height: 231px;
$sprite-text-range-hover1-image: '../img/user-interface-sprite.png';
$sprite-text-range-hover1: (0px, 164px, 0px, -164px, 14px, 48px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_text-range-hover1', );
$sprite-text-range-hover2-name: 'sprite_text-range-hover2';
$sprite-text-range-hover2-x: 32px;
$sprite-text-range-hover2-y: 164px;
$sprite-text-range-hover2-offset-x: -32px;
$sprite-text-range-hover2-offset-y: -164px;
$sprite-text-range-hover2-width: 14px;
$sprite-text-range-hover2-height: 48px;
$sprite-text-range-hover2-total-width: 267px;
$sprite-text-range-hover2-total-height: 231px;
$sprite-text-range-hover2-image: '../img/user-interface-sprite.png';
$sprite-text-range-hover2: (32px, 164px, -32px, -164px, 14px, 48px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_text-range-hover2', );
$sprite-text-range-name: 'sprite_text-range';
$sprite-text-range-x: 16px;
$sprite-text-range-y: 164px;
$sprite-text-range-offset-x: -16px;
$sprite-text-range-offset-y: -164px;
$sprite-text-range-width: 14px;
$sprite-text-range-height: 48px;
$sprite-text-range-total-width: 267px;
$sprite-text-range-total-height: 231px;
$sprite-text-range-image: '../img/user-interface-sprite.png';
$sprite-text-range: (16px, 164px, -16px, -164px, 14px, 48px, 267px, 231px, '../img/user-interface-sprite.png', 'sprite_text-range', );
$spritesheet-width: 267px;
$spritesheet-height: 231px;
$spritesheet-image: '../img/user-interface-sprite.png';
$spritesheet-sprites: ($sprite-ajax-loader, $sprite-alerte-big, $sprite-app-store-button, $sprite-button-close, $sprite-button-cross-active, $sprite-button-cross-default, $sprite-button-cross-light, $sprite-check-active, $sprite-check-active-2, $sprite-check-default, $sprite-check-default-2, $sprite-chevron-active-contact, $sprite-close-grey, $sprite-close-grey2, $sprite-close-overlay, $sprite-close-white, $sprite-fav-active, $sprite-fav-default, $sprite-fleche-bas-gris, $sprite-google-play-button, $sprite-grabbing, $sprite-img-exclusivite, $sprite-img-photo-small, $sprite-img-photo, $sprite-information, $sprite-lead-call-w, $sprite-lead-call, $sprite-lead-mail-w, $sprite-lead-mail, $sprite-pin, $sprite-radio-active, $sprite-radio-default, $sprite-select-arrow-down, $sprite-select-arrow-right, $sprite-select-arrow-up, $sprite-slider-range, $sprite-slider-range-active, $sprite-text-range-hover1, $sprite-text-range-hover2, $sprite-text-range, );
$spritesheet: (267px, 231px, '../img/user-interface-sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
