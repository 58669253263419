// Breadcrumbs
.breadcrumb {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0.8rem 1.6rem;
	background-color: $lighter-grey;
	border-radius: 3px;
	font-size: 1.2rem;
	list-style: none;

	> li {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;

		&:first-child {
			font-weight: $bold-font-weight;
		}

		&.active {
			color: $text-color;
		}

		&:not(:last-child) {
			text-decoration: underline;
		}

		a {
			color: $text-color;
		}

		span::first-letter, > a::first-letter {
			text-transform: capitalize;
		}

		+ li:before {
			background: url('/Content/App/dist/img/arrow-thin-grey-right.svg') center center no-repeat;
			width: 1rem;
			height: 1.1rem;
			content: '';
			padding-left: 0;
			vertical-align: 0;
			margin-left: 0.8rem;
			margin-right: 0.8rem;
			margin-bottom: 0.2rem;
		}
	}
}
