.slbc-warning {
	width: 100%;
	padding: 1.6rem 3.2rem 1.6rem 1.6rem;
	background-color: rgb(253, 242, 228);
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	text-align: center;
	color: #4c4c4c;
	font-size: 1.6rem;
	font-weight: 400;
	user-select: none;
	-webkit-user-select: none;
	position: relative;

	.slbc-warning-close {
		cursor: pointer;
		position: absolute;
		top: 0.8rem;
		right: 0.8rem;
	}

	p{
		margin: 0;
	}
}

@media screen and (max-width: 1023.5px) {
	.slbc-warning span {
		display: block;
	}
}

@media screen and (min-width: 1024px) {

	.slbc-warning .slbc-warning-close {
		top: 50%;
		transform: translateY(-50%);
	}
}
