.details-sidebar {
	.bandeau-mandataire {
		background-color: $gray2-lightest;
		justify-content: space-around;
		text-align: center;
		width: 100%;
		border-radius: 5px;
		margin-top: 2rem;
		padding: 3rem 1.5rem 4rem;

		.about {
			font-size: 1.8rem;
			margin-bottom: 1rem;
			font-weight: $bold-font-weight;
		}

		.tel {
			color: $brand-primary;
			font-weight: $bold-font-weight;
			font-size: 2.2rem;
		}

		.mandat-logo {
			margin: 0 auto;

			& + .mandataire-content {
				margin-top: 1rem;
			}
		}

		.mandataire-content {
			.mandataire-name {
				font-weight: $bold-font-weight;
				font-size: 1.5rem;
			}

			.smallLink {
				color: $gray2-darker;
				text-decoration: underline;

				&:hover {
					color: $orange-dark;
				}
			}
		}
	}
}
