// >> champ Input + bouton turquoise avec flèche blanche (via le footer on couvre tout le site)
.agb-input-group-with-arrow {
	width: 100%;
	display: table;
	border-collapse: separate;
	border-radius: 3px;
	overflow: hidden;
	position: relative;

	input.agb-input-before-arrow {
		height: 4rem;
		padding: 0.2rem 1.2rem;
		border: 0;
		border-radius: 3px 4px 4px 3px;
		outline: 0;
	}

	input#refAnnonce::placeholder,
	input#refAnnonce:-ms-input-placeholder,
	input#refAnnonce::-webkit-input-placeholder {
		color: $gray2-darkest;
	}

	button.agb-arrow-container-after-input {
		position: absolute;
		top: 0;
		right: 0;
		width: 4rem;
		height: 4rem;
		padding: 1rem 1.2rem;
		background-color: $deepblue;
		border: 0;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		.ico-arrow {
			width: 2rem;
			height: 2rem;

			&.ico-loupe {
				margin-left: -0.1rem;
				margin-top: -0.6rem;
			}
		}
	}
}
