@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
	// Color the label and help text
	.help-block,
	.control-label,
	.radio,
	.checkbox,
	.radio-inline,
	.checkbox-inline  {
		color: $text-color;
	}
	// Set the border and box shadow on specific inputs to match
	.form-control {
		border-color: $border-color;
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
		&:focus {
			border-color: darken($border-color, 10%);
			// $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
			// @include box-shadow($shadow);
		}
	}
	// Set validation states also for addons
	.input-group-addon {
		// color: $text-color;
		// border-color: $border-color;
		// background-color: $background-color;
	}

	@extend .default-transition;
	.input-group {
		@extend .default-transition;
		border-color: $border-color;
		// TODO: add cross background
	}
}

@mixin form-control-focus($color: $input-border-focus) {
	$color-rgba: rgba(red($color), green($color), blue($color), .6);
	&:focus {
		border-color: $color;
		outline: 0;
		// @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
	}
}

// Mixin: Custom Gutter
@mixin custom-gutter($size) {
	margin-left: -$size;
	margin-right: -$size;
	div[class^="col"] {
		padding-left: $size;
		padding-right: $size;
	}
}




