
.position {
	&.relative {
		position: relative;
	}
	&.absolute {
		position: absolute;
	}
}


.align {
	&.left { text-align: left !important; }
	&.center { text-align: center !important; }
	&.right { text-align: right !important; }
}

.clear { clear: both; }

.mt10 { margin-top: 10px; }
.mr10 { margin-right: 10px; }
.mb10 { margin-bottom: 10px; }
.ml10 { margin-left: 10px; }

.no-mt { margin-top: 0 !important; }
.no-mr { margin-right: 0 !important; }
.no-mb { margin-bottom: 0 !important; }
.no-ml { margin-left: 0 !important; }

.no-pt { padding-top: 0 !important; }
.no-pr { padding-right: 0 !important; }
.no-pb { padding-bottom: 0 !important; }
.no-pl { padding-left: 0 !important; }

.no-border {
	border: 0 !important;
}

.w100 { width: 100%; }

// centrage d'une image dans son conteneur
img {
	&.center {
		position: absolute;
		top: -999px;
		bottom: -999px;
		left: -999px;
		right: -999px;
		height: 100%;
		margin: auto;
	}
}

.display {
	display: block !important;
}

.display-none {
	display: none !important;
}

.cursor-default {
    cursor: default !important;
}
.cursor-pointer {
    cursor: pointer !important;
}

// prévention de l'affichage de bordure spécifique à un navigateur sur un élément :active
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin flexbox($args:null) {
	display: -webkit-box $args;
	display: -webkit-flex $args;
	display: -moz-flex $args;
	display: -ms-flexbox $args;
	display: flex $args;
}

@mixin flex-direction($args:null) {
	-webkit-flex-direction: $args; /* Safari 6.1+ */
    flex-direction: $args;
}

.flex {
	@include flexbox;
}

// = Selection
$prefixes: ("-webkit-", "-moz-", "-ms-", "");

@mixin selection($color, $background) {
	@each $prefix in $prefixes {
		::#{$prefix}selection {
			color: $color;
			background: $background;
			text-shadow: none;
		}
	}
}