// >>>>>>>> Custom Buttons

// Button with only border in primary color
.btn-primary-border-only {
    @include button-variant($brand-primary, transparent, $brand-primary);

    &:hover {
        background-color: $brand-primary-saturated;
        color: white;
    }
}

.btn-primary-ali-only {
	border: none;
	height: 4.1rem;
	font-size: $font-size-base-rem;
	background-color: $brand-primary;
	border-radius: 2px;
	padding: 1rem 1.6rem;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
	text-transform: none;
	border: none;
	font-size: 1.3rem;
	width: 17.3rem;

	i.glyphicon {
		transform: rotate(45deg);
		margin-right: 0.6rem;
		transition: all .2s ease-out;
	}

	@media screen and (max-width: $screen-xs-max) {
		height: auto;
	}

	&:hover {
		background-color: $orange-dark;
		border-color: $brand-primary-shadow !important;
		@include transition(all .2s ease-in);

		i.glyphicon {
			@include transition(all .2s ease-in);
			transform: rotate(-45deg);
		}
	}

	&:active {
		outline: none !important;
		color: #f3c1b4 !important;
		border-top: 3px solid #c13a17;
		border-color: $brand-primary-shadow !important;
		border-bottom: 0;
		padding: 0.8rem;
		background-color: $orange-dark !important;
		-webkit-transition-property: none;
		-moz-transition-property: none;
		-o-transition-property: none;
		transition-property: none;
	}

	&:focus {
		background-color: $brand-primary;
		outline: none !important;
		border-color: $brand-primary-shadow !important;
	}
}

.btn-primary-confier-only {
	text-transform: none;
	border: none;
	height: 4.1rem;
	font-size: $font-size-base-rem;
	background-color: $brand-primary;
	border-radius: 2px;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
	text-transform: none;
	border: none;
	border-bottom: 4px solid $brand-primary-shadow;
	font-size: 1.35rem;
	width: 17.3rem;

	i.glyphicon {
		transform: rotate(45deg);
		margin-right: 0.6rem;
		@include transition(all .2s ease-out);
	}

	@media screen and (max-width: $screen-xs-max) {
		height: auto;
		float: left !important;
		margin-top: 1rem;
	}

	&:hover {
		background-color: $orange-dark;
		@include transition(all .2s ease-in);

		i.glyphicon {
			@include transition(all .2s ease-in);
			transform: rotate(-45deg);
		}
	}

	&:active {
		color: #ddd !important;
		border-bottom: 0;
		padding: 0.8rem;
		-webkit-transition-property: none;
		-moz-transition-property: none;
		-o-transition-property: none;
		transition-property: none;
	}
}
.btn:focus {
	outline: none !important;
}
.liens-fixed  {
    display: none;
}
// Fixed buttons
@media screen and (max-width: $screen-xs-max) {
	.liens-fixed {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		position: fixed;
		bottom: 0;
		z-index: 10;
		width: 100%;
		margin-bottom: 2rem;
		padding: 0;
		box-sizing: content-box;
		border: none;
		color: rgba(255, 255, 255, 1);
		background-position: 50% 50%;
		background-origin: padding-box;
		background-clip: border-box;
		background-size: auto auto;

		&.visible-without-form {
			opacity: 1;
			transform: translateY(0px);
			transition: transform 200ms, opacity 200ms;
		}
		//when we see the Form on Mobile, the fixed buttons are hidden
		&.hidden-for-mobile {
			opacity: 0;
			transform: translateY(10px);
			transition: transform 200ms, opacity 200ms;
		}

		.lien-wrapper {

			&.lien-wrapper-left {
				padding-right: 0.7rem;
			}

			&.lien-wrapper-right {
				padding-left: 0.7rem;
			}
		}

		> .btn.btn-primary.mandataire-tel {
			margin-left: 0;
		}

		> .btn.btn-primary.mandataire-contact {
			margin-right: 0; 
		}

		.btn.btn-primary {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			text-transform: none;
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

			span {
				margin-right: 0.5rem;
			}

			&.wrap {
				margin-top: -3rem;
			}

			.action-ico {
				height: 1.5rem;
				margin-right: 0.5rem;
			}

			.pict-load {
				width: 2.2rem;
				height: 2.2rem;
			}

			&.agb-button {
				min-width: 10rem;
				width: calc(50% - 0.4rem);

				.tel-ico {
					margin-right: 0.8rem;
				}
			}
		}
	}
}

.hub-button-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 2.4rem;
}

.hub-button {
    padding: 0.8rem 2.4rem;
	border: 1px solid $text-color;	
    border-radius: 3rem;
	color: $text-color;
    font-size: 1.6rem;
    font-weight: 600;
	text-decoration: none;
    cursor: pointer;
    outline: 0;
	&:hover {
		color: white;
		background-color: $text-color;
	}
}