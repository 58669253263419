// Base styles
.input-group {
    border: 1px solid $gray-light;
    .form-control {
        &.tokenfield{
            border: 1px solid #ccc !important;
        }
    }
    &.has-error{
        border-color: red !important;
    }
}
.focus {
	border: 1px solid $deepblue !important;
}
.fill-height.input-group-btn{
    display: inline-block;
    position: absolute;
    right: 4rem;
    height: 100%;
    z-index: 3;

    button{
        height: 100%;
    }
}
.btn-wrapper {
	padding-right: 5.5rem;
}

.full-width{
    width:100%;
}

// Sizing options
.input-group-sm > .input-group-addon {
	padding: 0.3rem !important;

	> .btn {
		padding: 0.7rem 1rem !important;
	}
}

// Text input groups
.input-group-addon {
	padding: 0.3rem;
	background-color: #fff;
	border: 0;
	// fix typeahead tt-hint cover
	position: relative;
	z-index: 10;
	// Sizing
	&.input-sm {
		padding: $padding-small-vertical $padding-small-vertical;
		font-size: $font-size-base;
	}

	&.input-lg {
		padding: $padding-large-vertical $padding-large-vertical;
		font-size: $font-size-base;
	}
}
