body {
	@media (max-width: $screen-sm-max) {
		font-size: 62.5%;
	}
}


a {
    &:hover, &:focus {
        text-decoration: none;
    }
}
