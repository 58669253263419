.logo-desktop {
    display: block;
}

.sub-nav {
    font-size: 1.6rem;

    &:not(.opened) {
        display: none;
    }

    .all-news {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #0F495F;
    }

    p {
        font-size: 1.8rem;
    }
}

header {
    position: relative;
}

#navigation, nav-link-without-border {
    .nav-link {
        cursor: pointer;
        
        &.block {
            display: block;
        
            .item {
                display: inline-block;
                color: #2B2B2B;
            }
        }

        .item {
            color: #2B2B2B;
        }
    }
}

#header-overlay {
    display: none;
}

.actualite-layout {
    display: flex;
    flex-direction: column;

    .wrapper {
        padding: 1.6rem 0;
        border-bottom: 1px solid #eaeaea;
    }

    .text {
        float: left;
        width: calc(100% - 10.4rem);
        flex-direction: column;
        color: #2B2B2B;
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-style: normal;
        font-weight: normal;
    }

    .img {
        float: left;
        width: 8.8rem;
        height: 8.8rem;
        border-radius: 1rem;
        margin-right: 1.6rem;
        background-size: 180%;
        background-position: center;
    }

    .all-news {
        margin-top: 1.6rem;
    }

    a {
        cursor: pointer;
    }
}

// desktop
@media screen and (min-width: 1025px)  {
header {
    display: block;
		//right block of header
		.nav-right-block, .nav-right-block > div {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
		}
.logo-desktop {
        display: block;
        width: 16.2rem;
        margin-right: 4rem;
        padding-top: 0.8rem;
    }
.header-wrapper {
    height: 7.2rem;
    display: flex;
    flex-direction: column;
    line-height: 1.375;
    margin-top: 0.8rem;
}

.header-icon {
    position: relative;
    display: block;
    float: left;
    width: 5rem;
    height: 5.6rem;
    cursor: pointer;
}
.main-nav nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
 .main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    .nav-link {
        &.border--add {
            position: relative;
            &::after {
                content: " ";
                width: calc(100% - 1.6rem);
                height: 0.2rem;
                position: absolute;
                top: calc(100% - 0.8rem);
                left: 0.8rem;
                background: #0F495F;
                transition: height 200ms ease 0s;
                border-radius: 2.4rem;
            }
        }
    }
}
.main-nav nav>ul {
    display: flex;
    .item {
        padding: 1.7rem;
    }
}
.main-nav nav > ul li {
    padding: 0;
    display: inline-flex;
    color: rgb(43, 43, 43);
    font-size: 1.4rem;
    font-weight: 700;
}
.links ul{
    margin: 0;
    padding: 0;
    column-count: 3;
    list-style-type: none;
}
#office .links ul{
    column-count: 4;
}
.header-logo {
    height: 5.6rem;
    line-height: 5.6rem;
}

.nav-right-block>div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.sub-nav-container {
    position: relative;
    .sub-nav-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        max-width: 100%;
        .sub-nav {
            display: none;
            background-color: #f5f5f5;
            width: 100%;
            height: 20rem;
            line-height: 1.375;
            .container-links {
                display: flex;
                flex-direction: row; 
                margin-top: 4.8rem;
            .bloc{
                margin-right: 19rem;
                margin-left: 11rem;
                .links {
                    padding-bottom: 2.4rem;
                }            
                .serviciels{
                    padding-top: 2.4rem;
                    border-top: 1px solid rgb(234, 234, 234);
                }
            }
            }
            &.show-sub-nav {
				top: 0;
				left: 0;
				position: absolute;
				width: 100%;
                height: 48rem;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: stretch;
				z-index: 60001;
			}
            p {
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 2.4rem;
                font-family: Source Sans Pro;
            }
            a {
                color: #0F495F;
                outline: none;
                display: inline-block;
                position: relative;
                padding: 0.8rem 0.8rem 1rem;
                font-size: 1.6rem;
                font-family: Source Sans Pro;
                font-weight: 600;
                border-radius: 0.4rem;
                text-decoration: none;
            }
            .links-property {
                display: flex;
            .group-link {
                display: flex;
                flex-direction: column; 
            }
            }
        }
    }
}
}
#black-overlay {
	display: none;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0;
	position: absolute;
	left: 0;
	z-index: -8;
	&.overlay--show {
		display: block;
		z-index: 10004;
		opacity: 0.5;
	}
}

    .mobile-only {
        display: none !important;
    }

    .header-container {
        width: 100%;
        display: block;
        border-bottom: 1px solid #EAEAEA;
        font-size: 16px;
        overflow: hidden;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        padding: 0 1.6rem;
    }

    #header-icon {
        display: none;
    }

    header .header-logo {
        margin: 0.8rem 0;
    }

    #navigation {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-right: 0;
        justify-content: space-between;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #2B2B2B;
        margin-bottom: 0;
        padding-left: 0;

        .nav-link {
            display: inline-flex;
            list-style-type: none;
            padding: 1.7rem  1.7rem 0 1.7rem;
            white-space: pre;
    
            &.active {
                .item {
                    border-bottom: 2px solid #0F495F;
                }
            }

            .item {
                padding-bottom: 2.1rem;
                border-bottom: 2px solid transparent;
            }
        }
    }

    header .nav-right-block {
        margin-left: auto;
    }

    .sub-nav {
        display: grid;
        grid-template-columns: auto 41.4rem;
        grid-template-areas: "lk ac" "sv ac";
        padding: 2.4rem 12rem 2.4rem 12rem;

        .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #2B2B2B;
            margin: 0;
        }

        .content {
            padding: 1.6rem 0;
        }

        .links {
            border-bottom: 1px solid #EAEAEA;
            margin: 2.4rem 2.4rem 0 2.4rem;

            .list li {
                margin-bottom: 0.8rem;
                font-family: Source Sans Pro;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #0F495F;

                a {
                    color: #0F495F;
                }
            }
        }

        .actualites {
            margin: 2.4rem 2.4rem 0 2.4rem;
        }

        .serviciels {
            margin: 2.4rem 2.4rem 0 2.4rem;
        }
    }

    .sub-nav .links {
        grid-area: lk;
    }

    .sub-nav .actualites {
        grid-area: ac;
    }

    .sub-nav .serviciels {
        grid-area: sv;
    }

    header .sub-nav-container {
        position: absolute;
        background: white;
        width: 100%;
        z-index: 19999;
    }

    #header-overlay {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 20;
        background-color: #000;
        opacity: 0.25;
        height: 100%;
    }

    #header-overlay.opened {
        display: block;
    }
}

// Mobile
@media screen and (max-width: 767px)  {
    .header-container {
        width: 100%;
        display: block;
        border-bottom: 1px solid #EAEAEA;
        font-size: 16px;
        overflow: hidden;
    }

    #layout.show-header-only > *:not(#header) {
        display: none !important;
    }

    .hidden-for-header {
        display: none !important;
    }

    #header-icon {
        background: url(/Content/App/dist/img/burger.svg) no-repeat 50%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 4.8rem;
        height: 4.8rem;
        cursor: pointer;
        z-index: 2;

        &.closing {
            background: url(/Content/App/dist/img/close-grey.svg) no-repeat 50%;
            padding: 1.5rem;
            background-clip: content-box;
            overflow: hidden;
        }
    }

    header .header-logo {
        height: unset;

        .logo-desktop {
            height: 3.6rem;
            margin: 6px auto !important;
            padding-top: initial !important;
        }
    }

    .right {
        float: right;
    }

    #navigation {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 0;
        padding: 1.6rem;
        position: absolute;
        background: white;
        top: 48px;
        left: 0;
        z-index: 2;

        &:not(.opened) {
            display: none;
        }

        .nav-link {
            display: inline-flex;
            border-bottom: 1px solid #EAEAEA;
            list-style-type: none;

            > .item {
                font-style: normal;
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 3rem;
                color: #2B2B2B;
                padding: 1.2rem 0;
                flex-grow: 1;
            }
        }
        .nav-link-without-border {
            display: inline-flex;
            list-style-type: none;
            flex-direction: column;
            > .item {
                font-style: normal;
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 3rem;
                color: #2B2B2B;
                padding: 1.2rem 0;
                flex-grow: 1;
            }
            .title-broadcast {
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #707070;
                margin-bottom: 1.2rem;
            }
        }
    } 

    .nav-right-block {
        display: none !important;
    }

    .sub-nav-container {
        z-index: 30;
    }

    .sub-nav {
        padding: 3.2rem 1.6rem;

        .links {
            .list {
                padding: 0;

                li {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #0F495F;
                }
            }
        }
        
        .serviciels {
            display: none;
        }

        .back-chevron {
            width: 0.7rem;
            height: 1.2rem;
            margin-right: 0.7rem;
        }

        p { 
            &.title {
                font-style: normal;
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 2.4rem;
                margin-bottom: 0.4rem;
            }
        }

        ul { 
            &.list {
                margin-bottom: 2.4rem;

                li {
                    list-style-type: none;
                    border-bottom: 1px solid #EAEAEA;
                    padding: 1.2rem 0;

                    a {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        color: #0F495F;
                    }
                }
            }
        }
    }

    #back {
        margin-bottom: 2.4rem;
    }
}

// 1024
@media screen and (min-width: 768px) and (max-width: 1024px)  {
    body.show-header {
        overflow: hidden !important;
    }

    .header-container {
        width: 100%;
        display: block;
        border-bottom: 1px solid #EAEAEA;
        font-size: 16px;
        overflow: hidden;
    }

    #header-overlay{ 
        left: 0;
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 110;
        background-color: rgba(0,0,0,0.5);

        &.opened-mobile {
            display: block;
        }
    }

    #header-icon {
        background: url(/Content/App/dist/img/burger.svg) no-repeat 50%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 4.8rem;
        height: 4.8rem;
        cursor: pointer;
        z-index: 2;

        &.closing {
            background: url(/Content/App/dist/img/close-grey.svg) no-repeat 50%;
            padding: 1.5rem;
            background-clip: content-box;
            overflow: hidden;
        }
    }

    header .header-logo {
        height: unset;

        .logo-desktop {
            height: 3.6rem;
            margin: 6px auto 6px 76px !important;
            padding-top: initial !important;
        }
    }

    .right {
        float: right;
    }

    #navigation {
        display: flex;
        flex-direction: column;
        width: 36rem;
        margin-right: 0;
        padding: 1.6rem;
        position: absolute;
        background: white;
        top: 48px;
        left: 0;
        z-index: 120;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 49px);

        &:not(.opened) {
            display: none;
        }

        .nav-link {
            display: inline-flex;
            border-bottom: 1px solid #EAEAEA;
            list-style-type: none;

            > .item {
                font-style: normal;
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 3rem;
                color: #2B2B2B;
                padding: 1.2rem 0;
                flex-grow: 1;
            }
        }
        .nav-link-without-border {
            display: inline-flex;
            list-style-type: none;
            flex-direction: column;
            > .item {
                font-style: normal;
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 3rem;
                color: #2B2B2B;
                padding: 1.2rem 0;
                flex-grow: 1;
            }
            .title-broadcast {
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #707070;
                margin-bottom: 1.2rem;
            }
        }
    } 

    .nav-right-block {
        display: none !important;
    }

    .sub-nav-container {
        display: none;
        position: absolute;
        background: white;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 49px);
        width: 36rem;
        z-index: 130;

        &.opened {
            display: block;
        }
    }

    .sub-nav {
        padding: 3.2rem 1.6rem;

        .links {
            .list {
                padding: 0;

                li {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #0F495F;
                }
            }
        }
        
        .serviciels {
            display: none;
        }

        .back-chevron {
            width: 0.7rem;
            height: 1.2rem;
            margin-right: 0.7rem;
        }

        p { 
            &.title {
                font-style: normal;
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 2.4rem;
                margin-bottom: 0.4rem;
            }
        }

        ul { 
            &.list {
                margin-bottom: 2.4rem;

                li {
                    list-style-type: none;
                    border-bottom: 1px solid #EAEAEA;
                    padding: 1.2rem 0;

                    a {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        color: #0F495F;
                    }
                }
            }
        }
    }

    #back {
        margin-bottom: 2.4rem;
    }
}
