/* DEBUT CONTEXTE */
.contact-sidebar {
	padding-right: 0;

	.lead-form {

		@media screen and (max-width: $screen-md) {
			.mandataire-content {
				width: 11.4rem;
			}
		}

		.mandataire-adresse {
			&.truncate {
				width: 18rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			@media screen and (max-width: $screen-md) {
				&.truncate {
					width: 11.4rem;
				}
			}
		}
	}
}

.contact-bottom {
	.lead-form {
		margin-top: 5rem;
		padding-bottom: 0;
	}
}

.cnil-info, .cnil-info-modif {
	margin-top: 0.8rem;
	font-size: 1.1rem;
	line-height: normal;
	color: $gray2;
	text-align: left;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;

	a {
		cursor: pointer;
		text-decoration: underline;
		color: $gray-very-light;
	}

	@media screen and (max-width: $screen-xs-max) {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.cnil-info-modif {
    display: none;
}

/* FIN CONTEXTE */

/* DEBUT FORMULAIRE */

.lead-form {

	.contact-header {
		height: 5rem;

		@media screen and (max-width: 991px) {
			height: 7rem;

			.contact-menu-header {
				li {
					&.mail-section-link {
						padding: 1.9rem 1.6rem 1.9rem !important;
					}

					&.tel-section-link {
						&.bottom-contact {
							padding: 1.5rem 0 1.9rem 1.5rem !important;
						}
					}
				}
			}
		}

		.contact-menu-header {
			padding: 0;
			margin: 0;
			list-style-type: none;

			li {
				float: left;
				padding: 1.2rem 1.6rem 1.5rem;
				line-height: 1.2;
				width: 50%;
				font-size: $font-size-base-rem;

				&.mail-section-link {
					display: block;
					background-color: $gray2-light;
					border-top-right-radius: 5px;
					border-top-left-radius: 5px;
					text-align: center;
					font-size: $font-size-base-rem;
					font-weight: $bold-font-weight;

					&.coex {
						width: 50%;
						min-width: 15rem;
						max-width: 18rem;
						text-align: center;
					}

					&.coex-sidebar {
						width: 100%;
						text-align: center;
					}

					&.wt-200 {
						width: 20rem;

						@media screen and (min-width: 768px) and (max-width : 820px) {
							width: 16rem;
						}
					}
				}

				&.tel-section-link {
					display: block;
					padding-top: 0.5rem;

					#contact-annonceur-header-telephone-sidebar {
						cursor: pointer;
					}

					&.bottom-contact {
						float: right;
						padding: 0.1rem 0 1.5rem 2.5rem;
						width: auto;
					}

					.data-truncate-tel-ico {
						font-size: $font-size-base-rem;
						text-align: center;
						color: $brand-primary;

						img {
							margin-top: -0.2rem;
							width: 1.4rem;
							height: 1.4rem;
						}
					}

					.data-truncate-tel-label-container {
						width: 100%;
						border-radius: 100px;
						background: $brand-primary none repeat scroll 0 0;
						border-radius: 30px;
						margin-top: 0.2rem;
						text-align: center;
						padding: 0.3rem 0.8rem 0.3rem 0.8rem;

						&:hover {
							background-color: $orange-dark;
						}

						.data-truncate-tel-label {
							color: #fff;
							display: block;
							font-size: 1.2rem;
							text-decoration: none;
						}
					}

					.data-truncate-tel-label-container-bottom {
						background-color: white;

						.data-truncate-tel-label {
							display: flex;

							span {
								margin-top: 0.8rem;
								margin-right: 0.6rem;
							}
						}
					}
				}
			}
		}
	}

	.contact-body {
		background-color: $gray2-light;
		padding: 0.8rem;
		border-top-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;

		.form-group {
			margin-bottom: 0.8rem;

			@media screen and (min-width: $screen-sm-min) {
				&.pl-4 {
					padding-left: 0.4rem;
				}

				&.pr-4 {
					padding-right: 0.4rem;
				}
			}

			.form-control {
				height: 3.6rem;
				padding: 0.8rem 1rem;

				&.contact-message {
					height: 8.1rem;
				}
			}

			&.pb-8 {
				padding-bottom: 0.8rem;
			}

			&.mt-7 {
				margin-top: 0.7rem;
			}

			textarea {
				resize: vertical;
			}
		}

		.checkbox {
			label {
				font-size: $font-size-base-rem;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					font-size: 1.3rem;
				}
			}

			&.radio {
				margin-top: 0.8rem;
				margin-bottom: 0.8rem;
			}
		}

		&.border {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		.display-phone-container {
			font-size: 2.4rem;
			text-align: center;
			color: $brand-primary;

			.phone-data-tel, .phone-data-tel-multiple {
				cursor: default;

				&:focus, &:hover {
					color: $link-color;
				}

				@media screen and (max-width: $screen-sm) {
					cursor: pointer;

					&:focus {
						color: $link-hover-color;
					}
				}
			}

			.phone-data {
				margin-top: 0.8rem;
				font-weight: $bold-font-weight;
			}

			.phone-label {
				margin: 0 0 0.8rem 0;
				text-align: center;
				color: #000000;
				font-weight: $bold-font-weight;
				font-size: $font-size-base-rem;
			}

			.phone-separator {
				margin-top: 1rem;
				margin-bottom: 1.6rem;
				height: 0.1rem;
				background-color: #b8b8b8;
				width: 100%;
				text-align: center;
			}
		}

		.contact-action-link {
			width: auto;
			margin: 0 auto;

			.contact-button {
				width: auto;
				margin: 1.6rem auto 0.8rem;

				button {
					width: auto;
					margin: 0 auto;
					height: 4rem;

					&.load {
						.envelope-icon {
							display: none;
						}
					}

					@media screen and (min-width: $screen-sm-min) and (max-width: 840px) {
						&.width-for-coex {
							padding: 0 1.2rem;

							.envelope-icon {
								margin-right: 0.4rem;
							}
						}
					}

					.pict-load, .envelope-icon {
						margin: auto 0.8rem auto auto; // fix bug Safari 9 et 10
					}
				}

				.envelope-icon {
					width: 1.8rem;
					height: 1.8rem;
					min-width: 1.8rem;
					min-height: 1.8rem;
					padding-right: 0.2rem;
					background: url(/Content/App/dist/img/white-envelope.svg) no-repeat center center;
					background-size: 1.8rem 1.8rem;
				}
			}

			.lien-cgu {
				font-size: 1.2rem;
				text-align: center;

				a {
					color: #3B3D41;
					text-decoration: underline;
				}
			}
		}

		.champ-obligatoire {
			text-align: right;
			font-size: 1.2rem;
		}

		.know-more-text {
			display: none;
			font-size: 1.1rem;
			margin-top: 0.5rem;
		}
	}

	.contact-recap {
		background-color: $gray-lightest;
		margin-bottom: 1.5rem;
		border-radius: 3px;
		padding-bottom: 1rem;

		.succes-envoi-demande-contact {
			padding-top: 1.5rem;
			text-align: center;
			line-height: 2.5rem;
			color: $green;

			.tel-container-action {
				cursor: pointer;
			}

			.succes-icon {
				width: 2.1rem;
				height: 2.1rem;
				background-color: $green-lightest;
				border-radius: 16px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1.1rem;
				margin-top: 3rem;
				line-height: 1.2;
			}

			.show-tel {
				color: $brand-primary;
				font-size: $font-size-base-rem;

				.tel-icon {
					width: 1.4rem;
					height: 1.4rem;
				}
			}

			.sucess-image {
				height: 10rem;
				width: 18rem;
				margin-top: 2rem;
				margin-bottom: 2rem;
				margin-left: auto;
				margin-right: auto;
			}

			.message-title {
				font-size: $font-size-base-rem;
				color: $green-lightest;
				font-weight: $bold-font-weight;
				margin-bottom: 0.8rem;
				margin-left: 0.8rem;
				margin-right: 0.8rem;
			}

			.coex-phone {
				text-align: center;
				color: $brand-primary;
			}

			.coex-society {
				color: #565656;
				font-weight: $bold-font-weight;
				text-align: center;
			}
		}

		.message-alerte {
			color: $green-lightest;
			padding: 0.8rem;
			margin: 0;
			font-weight: normal;
		}

		.criteres {
			padding: 1rem 0.5rem 1.4rem 0.5rem;
			background-color: #E8E8E8;
			margin-right: 0.8rem;
			margin-left: 0.8rem;
			margin-top: 1rem;

			.rappel-demande {
				margin-bottom: 1rem;
			}

			.recap-titre {
				font-size: 1.7rem;
				font-weight: $bold-font-weight;
				margin-bottom: 0;
				text-align: center;
			}

			.info-utilisateur {
				color: #565656;
				opacity: 0.63;
				text-align: center;
				font-style: italic;
			}

			.message-contact {
				margin-bottom: 1.6rem;
			}
		}
	}

	.btn-envoyer {
		text-transform: none;
		border: none;
	}

	.mt-10 {
		margin-top: 1rem;
	}

	.checkbox {
		padding-left: 0;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;

		input[type=checkbox] + label {
			padding-left: 2.4rem;
			cursor: pointer;

			&:before {
				content: "";
				width: 1.9rem;
				height: 1.9rem;
				border: solid 1px $table-border-color;
				position: absolute;
				left: 0;
				top: 0.2rem;
				background: white;
				margin-left: 0;
			}
		}

		input[type=checkbox]:checked ~ label {
			&:before {
				border: solid 1px $deepblue;
				background: $deepblue;
			}
			&:after {
				content: '';
				position: absolute;
				left: 5.5px;
				top: 3px;
				background: white;
				width: 6px;
				height: 10px;
				border: solid #ffffff;
				border-width: 0 2px 2px 0;
				border-radius: 0px 0.7px 0.7px 0.7px;
				background-color: transparent;
				-webkit-transform: rotate(45deg) skew(4deg);
				-ms-transform: rotate(45deg) skew(4deg);
				transform: rotate(45deg) skew(4deg);
			}
		}
	}

	.tel-container-action {
		cursor: pointer;
	}

	.parsley-errors-list {
		font-size: 1.2rem;
		line-height: normal;
	}

	.conversion-toggle {
		position: relative;
		top: -0.1rem;
		width: 1.4rem;
		height: 1.4rem;
	}

	textarea:focus,
	input[type=tel]:focus,
	input[type=email]:focus,
	input[type=text]:focus {
		border: solid 1px $deepblue;
	}

	input[type=checkbox]:checked ~ label,
	input[type=radio]:checked ~ label {
		&:before {
			border: solid 1px $deepblue;
			background: $deepblue;
		}
		&:after {
			content: '';
			position: absolute;
			left: 5.5px;
			top: 3px;
			background: white;
			width: 6px;
			height: 10px;
			border: solid #ffffff;
			border-width: 0 2px 2px 0;
			border-radius: 0px 0.7px 0.7px 0.7px;
			background-color: transparent;
			-webkit-transform: rotate(45deg) skew(4deg);
			-ms-transform: rotate(45deg) skew(4deg);
			transform: rotate(45deg) skew(4deg);
		}
	}
}

/* FIN FORMULAIRE */
