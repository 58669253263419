.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    &.fa-angle-left:before {
        content: "\f104";
    }

    &.fa-angle-right:before {
        content: "\f105";
    }
    
    &.fa-envelope:before {
        content: "\f0e0";
    }
    
    &.fa-check:before {
        content: "\f00c";
    }

    &.fa-circle-thin:before {
        content: "\f1db";
    }

    &.fa-circle:before {
        content: "\f111";
    }

    &.fa-spin {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
    }
    &.fa-circle-o-notch:before {
        content: "\f1ce";
    }
}