@keyframes elastic {
	0% {
		opacity: 1;
		transform: none;
	}

	5% {
		opacity: 0;
	}

	15% {
		transform: translate3d(-100%, 0, 0);
	}

	60% {
		transform: translate3d(4px, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes wobble {
	0% {
		transform: none
	}

	15% {
		transform: translate3d(25%, 0, 0) rotate3d(0, 0, 1, -5deg)
	}

	30% {
		transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, 3deg)
	}

	45% {
		transform: translate3d(15%, 0, 0) rotate3d(0, 0, 1, -3deg)
	}

	60% {
		transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 2deg)
	}

	75% {
		transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, -1deg)
	}

	100% {
		transform: none
	}
}

.wobble:hover .svg,
.wobble:hover .cloche {
	animation: wobble 1s ease-in-out;
}

button.agb-button, .agb-button {
	/* reset all default style of a button */
	background: none;
	color: inherit;
	border: 0;
	padding: 0;
	font: inherit;
	text-decoration: none;
	cursor: pointer;
	outline: 0;
	vertical-align: baseline;
}

.agb-button {
	/* charte AGB */
	padding: 0 2.4rem;
	border-radius: 3rem;
	font-size: $font-size-base-rem;
	font-weight: 600;
	letter-spacing: 0.4px;
	/*besoin maison */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 767px) {
		margin: 0 auto;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	&:not(.wobble):hover {
		span:not(.loader) {
			animation: elastic 0.4s ease-in-out;
		}

		.tel-elastic {
			background: url("/Content/App/dist/img/tel.svg") no-repeat center center;
			background-size: 1.8rem 1.8rem;
		}
	}

	span {
		display: flex;
		width: 2rem;
		height: 2rem;
		min-width: 2rem;
		min-height: 2rem;

		img {
			width: 100%;
			height: 100%;
			min-width: 2rem;
			min-height: 2rem;
		}
	}

	&.buttonFill {
		padding: 0 2.4rem;
		border-radius: 3rem;
		font-size: $font-size-base-rem;
		font-weight: 600;
		letter-spacing: 0.4px;

		svg {
			margin-right: 0.4rem;
			margin-top: -0.2rem;
		}
	}


	&.orangeAgbLine {
		color: $brand-primary;
		background-color: transparent;
		border: 1px solid $brand-primary;
		transition: background-color 0.2s;

		&:hover {
			color: #ffffff;
			background-color: $brand-primary !important;
			border: none;

			svg {
				fill: #ffffff;
			}
		}
	}

	&.extraSmall {
		height: 3.2rem;
		min-width: 12.5rem;
	}

	&.small {
		height: 3.6rem;
		min-width: 12.5rem;
	}

	&.medium {
		line-height: 4rem;
		min-width: 20.5rem;
	}

	&.large {
		height: 4.8rem;
		min-width: 15.6rem;
	}

	&.orangeAgb {
		color: #ffffff;
		background-color: $brand-primary;
		transition: background-color 0.2s;

		&:hover {
			background-color: $orange-dark !important;
		}
	}

	&.animated {
		animation-fill-mode: both;
	}

	.ico-svg, .loader {
		margin-right: 0.8rem;
		margin-top: -0.2rem;
	}

	.pict-load {
		display: none;
		width: 2rem;
		height: 2rem;
		margin-right: 0.8rem;
	}

	.tel-elastic {
		height: 1.8rem;
		width: 1.8rem;
		background: url("/Content/App/dist/img/tel-orange.svg") no-repeat center center;
		background-size: 1.8rem 1.8rem;
	}
}

.medium-icon {
	height: 4rem;
	line-height: 3.9rem;
	width: 4rem;
	padding: 0;
	text-align: center;
}

.small-container {
	height: 3.8rem;
	&.agb-button-container {
		@media screen and (max-width: 771px) {
			width: auto;
		}
	}
}

.medium-container {
	height: 4.4rem;
}
