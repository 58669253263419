.range {
	.range-group {
		display: table;
		width: 100%;

		.input-group {
			display: table-row;

			> * {
				display: table-cell;
			}

			> input {
				border: transparent;
				height: 4.8rem;

				&:focus {
					border-bottom: 1px solid $gray-darker;
					border-top: 1px solid $gray-darker;
					border-left: 1px solid $gray-darker;
					border-right: transparent;

					& + .range-link {
						@include sprite($sprite-text-range-hover1);
						height: 4.7rem;
					}

					& + .addon {
						border-bottom: 1px solid $gray-darker;
						border-top: 1px solid $gray-darker;
						border-right: 1px solid $gray-darker;
						height: 4.6rem;
						line-height: 45px;
					}
				}
			}

			> .range-link + input:focus {
				border-left: none;
			}
		}

		.range-link {
			width: 1.4rem;
			@include sprite($sprite-text-range);
			height: 4.7rem;
			vertical-align: middle;

			&.focus-after {
				@include sprite($sprite-text-range-hover2);
				height: 4.7rem;
			}
		}
	}

	legend {
		font-size: 1.8rem;
		font-weight: $bold-font-weight;
		text-transform: none;
		border: none;
		margin-bottom: 0;
	}

	.addon {
		height: $input-height-base;
		line-height: $input-height-base - 1;
		padding: 0 0.8rem 0 0;
		background-color: #fff;
		border: 0;
		font-weight: $bold-font-weight;
		color: #000;
		width: 2.5rem;
		text-align: right;
		vertical-align: middle;
		white-space: nowrap;
	}
}
