
.error-messages {
	padding: 0.8rem;
}

.parsley-errors-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	font-size: $font-size-base-rem;
	line-height: 1.6rem;
	opacity: 0;
	transition: all .3s ease-in;

	&.filled {
		margin-top: 0.3rem;
		opacity: 1;
		color: $state-error-text
	}
}

.fa-success {
	color: $brand-success;
}

// Avoid crap comportements with icheck plugin & parsley...
.checkbox.unique {
	.parsley-errors-list {
		display: none !important;
	}
}
