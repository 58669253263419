.checkbox, .radio {
	padding-left: 1.2rem; // reset bootstrap
	input[type=checkbox], input[type=radio] {
		display: none;
	}

	input[type=checkbox] + label, input[type=radio] + label {
		padding-left: 2.5rem;
	}

	input[type=checkbox] + label::before, input[type=radio] + label::before {
		@include sprite($sprite-check-default);
		display: inline-block;
		padding: 0;
		content: " ";
		position: absolute;
		margin-left: -2.5rem;
	}
}

.checkbox {
    input[type=checkbox] + label::before, input[type=radio] + label::before  {
		@include sprite-position($sprite-check-default);
    }

    input[type=checkbox]:checked + label::before, input[type=radio]:checked + label::before {
		@include sprite-position($sprite-check-active);
    }
}

.radio {
    input[type=checkbox] + label::before, input[type=radio] + label::before  {
		@include sprite-position($sprite-radio-default);
    }

    input[type=checkbox]:checked + label::before, input[type=radio]:checked + label::before {
		@include sprite-position($sprite-radio-active);
    }
}
