//$chosen-sprite-down: image-url('#{$img-base-path}user-interface/select-arrow_down.png') !default;
// $chosen-sprite-down-retina: image-url('chosen-sprite@2x.png') !default;

/* @group Base */
.chosen-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	font-size: 1.7rem;
	zoom: 1;
	*display: inline;
	user-select: none;

	* {
		box-sizing: border-box;
	}

	@extend .default-transition;

	.chosen-drop {
		position: absolute;
		top: 100%;
		left: -999rem;
		z-index: 16000;
		width: 100%;
		border: 1px solid #aaa;
		border-top: 0;
		background: #fff;
	}

	&.chosen-with-drop .chosen-drop {
		left: 0;
	}

	a {
		cursor: pointer;
	}

	.search-choice, .chosen-single {
		.group-name {
			margin-right: 0.4rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-weight: normal;
			color: #999999;

			&:after {
				content: ":";
				padding-left: 0.2rem;
				vertical-align: top;
			}
		}
	}

	.prefix {
		margin-right: 1.6rem;
	}
}
/* @end */

/* @group Single Chosen */
.chosen-container-single {
	.chosen-single {
		position: relative;
		display: block;
		overflow: hidden;
		padding: 0 0 0 0.8rem;
		height: $input-height-base;
		border: 1px solid #aaa;
		background-color: #fff;
		background-clip: padding-box;
		color: #444;
		text-decoration: none;
		white-space: nowrap;
		line-height: $input-height-base - 1;
	}

	.chosen-default {
		color: #999;
	}

	.chosen-single span {
		// display: block;
		overflow: hidden;
		margin-right: 2.6rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.chosen-single-with-deselect span {
		margin-right: 3.8rem;
	}

	.chosen-single abbr {
		position: absolute;
		top: 0.6rem;
		right: 2.6rem;
		display: block;
		@include sprite($sprite-select-arrow-down);
		font-size: 0.1rem;
	}

	&.chosen-disabled .chosen-single abbr:hover {
		background-position: -42px -10px;
	}

	.chosen-single div {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 5rem;
		height: 100%;

		b {
			display: block;
			position: absolute;
			right: 1.4rem;
			top: 1.4rem;
			width: 0.9rem;
			height: 1.7rem;
			background: url("/Content/App/dist/img/arrow-dark-red-right.svg") center center no-repeat;
			transform: rotate(90deg);
			background-position: unset;
			transform-origin: center;
		}
	}

	.chosen-search {
		position: relative;
		z-index: 1010;
		margin: 0;
		padding: 0.3rem 0.4rem;
		white-space: nowrap;

		input[type="text"] {
			margin: 1px 0;
			padding: 0.4rem 2rem 0.4rem 0.5rem;
			width: 100%;
			height: auto;
			outline: 0;
			border: 1px solid #aaa;
			@include sprite($sprite-select-arrow-down);
			//background: #fff $chosen-sprite-down no-repeat;
			//background-position: right 10px bottom 16px;
			font-size: $font-size-base-rem;
			line-height: normal;
		}
	}

	.chosen-drop {
		margin-top: 0;
		background-clip: padding-box;
	}

	&.chosen-container-single-nosearch .chosen-search {
		position: absolute;
		left: -999rem;
	}
}
/* @end */

/* @group Results */
.chosen-container .chosen-results {
	color: #444;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
	max-height: 24rem;
	-webkit-overflow-scrolling: touch;

	li {
		display: none;
		margin: 0;
		padding: .8rem 1.6rem;
		list-style: none;
		line-height: $input-height-base - 20;
		word-wrap: break-word;
		-webkit-touch-callout: none;

		&.active-result {
			display: list-item;
			cursor: pointer;

			& + .active-result {
				border-top: 1px solid $gray-lighter;
			}
		}

		&.disabled-result {
			display: list-item;
			color: #ccc;
			cursor: default;
		}

		&.highlighted {
			background-color: lighten($brand-primary, 10%);
			color: #fff;
		}

		&.no-results {
			color: #777;
			display: list-item;
			background: #f4f4f4;
		}

		&.group-result {
			display: list-item;
			font-weight: $bold-font-weight;
			cursor: default;
		}

		&.group-option {
			padding-left: 1.5rem;
		}

		em {
			font-style: normal;
			text-decoration: underline;
		}
	}
}
/* @end */

/* @group Multi Chosen */
.chosen-container-multi {
	.chosen-choices {
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0 0.5rem;
		width: 100%;
		height: auto !important;
		height: 1%;
		border: 1px solid #aaa;
		background-color: #fff;
		cursor: text;
	}

	.chosen-choices li {
		float: left;
		list-style: none;

		&.search-field {
			margin: 0;
			padding: 0;
			white-space: nowrap;

			input[type="text"] {
				margin: 0.1rem 0;
				padding: 0;
				height: 2.5rem;
				outline: 0;
				border: 0 !important;
				background: transparent !important;
				color: #999;
				font-size: 100%;
				line-height: normal;
			}
		}

		&.search-choice {
			position: relative;
			margin: 0.3rem 0.5rem 0.3rem 0;
			padding: 0.3rem 2rem 0.3rem 0.5rem;
			border: 1px solid #aaa;
			max-width: 100%;
			background-color: #eeeeee;
			background-size: 100% 1.9rem;
			background-repeat: repeat-x;
			background-clip: padding-box;
			color: #333;
			line-height: 1.3rem;
			cursor: default;

			span {
				word-wrap: break-word;
			}

			.search-choice-close {
				position: absolute;
				top: 0.4rem;
				right: 0.3rem;
				display: block;
				@include sprite($sprite-select-arrow-down);
				font-size: 0.1rem;
			}
		}

		&.search-choice-disabled {
			padding-right: 0.5rem;
			border: 1px solid #ccc;
			background-color: #e4e4e4;
			color: #666;
		}

		&.search-choice-focus {
			background: #d4d4d4;

			.search-choice-close {
				background-position: -42px -10px;
			}
		}
	}

	.chosen-results {
		margin: 0;
		padding: 0;
	}

	.chosen-drop .result-selected {
		display: list-item;
		color: #ccc;
		cursor: default;
	}
}
/* @end */

/* @group Active  */
.chosen-container-active {
	.chosen-single {
		// border: 1px solid #5897fb;
	}

	&.chosen-with-drop {
		.chosen-single {
			@extend .default-transition;
			border: 1px solid $dark-grey;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

		.chosen-single div {
			border-left: none;

			b {
				transform: rotate(-90deg);
			}
		}
	}

	.chosen-choices {
		border: 1px solid #5897fb;

		li.search-field input[type="text"] {
			color: #222 !important;
		}
	}
}
/* @end */

/* @group Disabled Support */
.chosen-disabled {
	opacity: 0.5 !important;
	cursor: default;
	.chosen-single {
		cursor: default;
	}
	.chosen-choices .search-choice .search-choice-close {
		cursor: default;
	}
}
/* @end */

/* @group Right to Left */
.chosen-rtl {
	text-align: right;

	.chosen-single {
		overflow: visible;
		padding: 0 0.8rem 0 0;
	}

	.chosen-single span {
		margin-right: 0;
		margin-left: 26px;
		direction: rtl;
	}

	.chosen-single-with-deselect span {
		margin-left: 3.8rem;
	}

	.chosen-single div {
		right: auto;
		left: 0.3rem;
	}

	.chosen-single abbr {
		right: auto;
		left: 26px;
	}

	.chosen-choices li {
		float: right;

		&.search-field input[type="text"] {
			direction: rtl;
		}

		&.search-choice {
			margin: 0.3rem 0.5rem 0.3rem 0;
			padding: 0.3rem 0.5rem 0.3rem 1.9rem;

			.search-choice-close {
				right: auto;
				left: 0.4rem;
			}
		}
	}

	&.chosen-container-single-nosearch .chosen-search,
	.chosen-drop {
		left: 999rem;
	}

	&.chosen-container-single .chosen-results {
		margin: 0 0 0.4rem 0.4rem;
		padding: 0 0.4rem 0 0;
	}

	.chosen-results li.group-option {
		padding-right: 1.5rem;
		padding-left: 0;
	}

	&.chosen-container-active.chosen-with-drop .chosen-single div {
		border-right: none;
	}

	.chosen-search input[type="text"] {
		padding: 0.4rem 0.5rem 0.4rem 2rem;
		@include sprite($sprite-select-arrow-down);
		direction: rtl;
	}

	&.chosen-container-single {
		.chosen-single div b {
			background-position: 6px 2px;
		}

		&.chosen-with-drop {
			.chosen-single div b {
				@include sprite-position($sprite-select-arrow-up);
			}
		}
	}
}

/* @end */

/* @group Retina compatibility */
// @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
// 	.chosen-rtl .chosen-search input[type="text"],
// 	.chosen-container-single .chosen-single abbr,
// 	.chosen-container-single .chosen-single div b,
// 	.chosen-container-single .chosen-search input[type="text"],
// 	.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
// 	.chosen-container .chosen-results-scroll-down span,
// 	.chosen-container .chosen-results-scroll-up span {
// 		background-image: $chosen-sprite-down-retina !important;
// 		background-size: 52px 37px !important;
// 		background-repeat: no-repeat !important;
// 	}
// }

