/*
* TOC
*
* BASE
* HELPER
* COMPOSANTS
* BLOCS
* PAGES
*/

@charset "UTF-8";

// > BASE
@import "_variables";
@import "_bootstrap";
@import "fa";

// > HELPER
@import "agorabiz/_helpers";


// > COMPOSANTS (sprites, galerie, selects, checkboxes, etc...)
@import "agorabiz/ui/base";

// > BLOCS de vues partielle
@import "agorabiz/common/components/agb-buttons";
@import "agorabiz/common/components/warning-banner";
@import "agorabiz/partials/_header-menu";
@import "agorabiz/partials/footer";
@import "agorabiz/partials/footerExtraLinks";
@import "agorabiz/partials/seoFooter";
@import "agorabiz/partials/_formMultiSteps";
@import "agorabiz/ui/contact";
@import "agorabiz/partials/lead/_form.scss";
@import "agorabiz/partials/contact/mandataire.scss";
@import "agorabiz/pages/common";

//Note : penser à ajouter à landing-page-coworking.scss les imports faits ici pouvant être utiles aussi là bas

html,
body {
	font-size: 62.5%; // to define 1rem = 10px;
}