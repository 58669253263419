// Wrapper and base class
.navbar {
    // border: 1px solid transparent;
    @media (max-width: $screen-sm-max) {
        min-height: $navbar-height - 20;
    }
}

// Navbar collapse (body)
.navbar-collapse {
    padding-right: 0;
    padding-left:  0;
}

.navbar-default { border: none; }

// Brand/project name
.navbar-brand {
    padding: 1.05rem $navbar-padding-horizontal;
}

// Navbar toggle
.navbar-toggle {
	border: none;
	// float: right;
	// margin-right: $navbar-padding-horizontal;
	// padding: 9px 10px;
	&.back {
		@extend .glyphicon;
		@extend .glyphicon-menu-left;
		position: absolute;
		left: 2.4rem;
		font-size: 3.2rem;
	}
	// Bars
	.icon-bar {
		background-color: $gray2-dark !important;
		width: 2rem;
		height: 0.2rem;
		border-radius: 1px;
	}

	.icon-bar + .icon-bar {
		margin-top: 0.5rem;
	}
}

// Navbar form
.navbar-form {
    // Undo 100% width for pull classes
    @media (min-width: $grid-float-breakpoint) {
        width: 100%;
    }
}
