body.modal-open {
	width: 100%;
	overflow-y: hidden !important;
}

.modal {

	.modal-dialog {
		max-width: 99%;

		.modal-content {
			background-color: $gray-lightest;
			-webkit-box-shadow: 0 0.4px 18px 0 rgba(0, 0, 0, 0.2);
			box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);

			.modal-header {
				background-color: $gray-lightest;
				border-bottom: none;

				.modal-title {
					text-transform: uppercase;
					font-size: 1.9rem;
					width: 88%;
					margin-left: auto;
					margin-right: auto;
				}

				.modal-subtitle {
					font-size: 1.8rem;
					margin-top: 1rem;
				}
			}

			.modal-body {
				background-color: $gray-lightest;
				font-size: $font-size-base-rem;

				textarea {
					min-height: 9.6rem;
				}

				a:hover {
					cursor: pointer;
				}
			}

			.modal-footer {

				p.bg-success,
				p.bg-danger {
					font-size: 1.2rem;
					margin-top: 1.6rem;
					text-align: center;
				}
			}

			.button-close {
				display: block;
				border: 0;
				background-color: transparent;
				position: absolute;
				right: 1rem;
				margin-top: 1rem;
				top: 0;
				cursor: pointer;
				z-index: 2;
				padding: 1rem;
				background: url('/Content/App/dist/img/close-grey.svg') center center no-repeat;
				width: 2rem;
			}
		}
	}

	// > handle right modals (for large devices only)
	&.modal-right {
		@media screen and (min-width: $screen-sm-max) {
			>.modal-dialog {
				position: absolute;
				right: 0;
				width: 36rem;
			}
		}
	}

	// > handle right modals (for large devices only)
	&.modal-left {
		@media screen and (min-width: $screen-sm-max) {
			>.modal-dialog {
				position: absolute;
				left: 0;
				width: 36rem;
			}
		}
	}
}