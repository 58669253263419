// temporary footer
#footer-end, #footer-essential-links ul li {
    font-size: 1.6rem;
}

#footer-essential-links {
    background-color: #2B2B2B;
    color: white;
    width: 100%;
    margin: -0.1rem auto;
    ul {
        width: 100%;
        max-width: 112rem;
        margin: 0 auto;
        padding: 0 3.2rem 2.4rem;

        li {
            display: inline;
            list-style: none;
            &:not(:last-child)::after {
                content: ' - ';
                color: white;
            }
            a {
                color: white;
                text-decoration: none;
            }
        }
    }
}

#footer-end {
    margin: 0 auto;
    padding: 0 1.6rem 3.2rem;
    text-align: center;
}

@media screen and (min-width: 992px) {
    #footer-essential-links {
        text-align: center;
    }
}
