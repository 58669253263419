﻿// mobile first defaults
.row {
    &.no-gutter {
        margin-left: 0;
        margin-right: 0;
    
        [class*='col-'] {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.no-gutter-exterior {
        margin-left: 0;
        margin-right: 0;

        [class*='col-']:first-child {
            padding-left: 0;
        }
        [class*='col-']:last-child {
            padding-right: 0;
        }
    }

    &.no-margin-bottom {
        margin-bottom: 0 !important;
    }
}

// Small gutters
.sm-gutter {
	@include custom-gutter(5px);
}