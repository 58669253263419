.modal-multisteps {
	.modal-multisteps-header {
		.step-header {
			background-color: $gray-lightest;
			padding-top: 1.5rem;

			&.header-empty {
				background-color: $body-bg;
			}

			.modalTitle {
				text-transform: initial;

				> span {
					color: $modal-active-step-color;
					font-weight: $bold-font-weight;
				}
			}

			.title {
				text-align: center;
				margin-bottom: 0.6rem;
				font-size: 2.5rem;
			}

			.steps {
				text-align: center;
				margin-bottom: 1rem;

				.step {
					width: 10.7rem;
					height: 0.6rem;
					border-radius: 2px;
					margin-right: 1.2rem;
					display: inline-block;
				}

				.active {
					background-color: $modal-active-step-color;
				}

				.inactive {
					background-color: $modal-inactive-step-color;
				}

				.fullwidth {
					width: 60%;
				}
			}
		}
	}

	.modal-body {
		.contact-form {
			padding: 0 2rem;

			p {
				text-align: center;
				font-size: 2.1rem;
			}

			.title.first {
				text-align: center;
				font-size: 3.2rem;
				margin-bottom: 4rem;
			}

			.relation-container {
				padding-bottom: 2rem;
			}

			.checkbox input[type=checkbox] {
				display: block;
				display: initial;
			}

			.label-ckeckbox {
				font-size: 1.2rem;
				font-weight: normal;
				padding-left: 0.9rem;

				span {
					display: block;
					font-weight: normal;
					font-size: 2.1rem;
					font-weight: $bold-font-weight;

					&.mandat-relation {
						font-size: $font-size-base-rem;
					}
				}
			}

			.continue-button {
				display: block;
				margin-top: 5rem;
				margin-bottom: 3.8rem;
			}

			.action-title {
				display: block;
				text-align: center;
				color: #db361d;
				font-size: 3.6rem;
				margin-bottom: 1rem;
				margin-top: 1.5rem;
				line-height: normal;
			}

			.terminer-button {
				color: $modal-link-color;
				margin-top: 2rem;
				margin-bottom: 1.5rem;
				text-decoration: underline;
				display: block;
				text-align: center;
			}

			.button-container {
				text-align: center;

				button, a {
					margin: 4rem auto 3rem;
					text-transform: none;

					&.large {
						max-width: 20rem;
						white-space: inherit;
						padding: 1.5rem 3.5rem;
					}

					&.white {
						background-color: white;
						color: $brand-primary;
					}
				}

				.btn + .btn {
					margin-left: 2.5rem;
				}
			}

			.last-button-container {
				width: 100%;
				margin: 0.5rem auto 1rem;
				text-align: center;

				.agb-button {
					margin: 0 auto;
				}
			}

			.parsley-errors-list li {
				color: $state-error-text;
			}

			.parsley-error {
				border-color: $state-error-text !important;
				color: $gray;
				margin-bottom: 1rem;

				input::-webkit-input-placeholder {
					color: $state-error-text !important;
				}

				input:-moz-placeholder { /* Firefox 18- */
					color: $state-error-text !important;
				}

				input::-moz-placeholder { /* Firefox 19+ */
					color: $state-error-text !important;
				}

				input:-ms-input-placeholder {
					color: $state-error-text !important;
				}
			}

			.tokentags-tag-item {
				padding: 0.9rem 1.3rem;
				height: 3.6rem;
				font-size: $font-size-base-rem;
			}

			.tokentags-input {
				height: 4.4rem;
			}

			.localites-tags-unique {
				border: 1px solid #ccc;
			}

			#error-message-container, #error-message-container-mail {
				width: 60%;
				margin: 2rem auto 0;
				color: red;
				font-size: 1.5rem;
				text-align: center;
			}

			&#service-client-form {
				.cnil-info, .cnil-info-modif {
					padding: 0.8rem 0 1.6rem;
				}
			}
		}
	}

	ul li {
		font-size: 1.9rem;
		line-height: 1.6rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		.modal-body {
			.contact-form {
				.button-container {
					.btn + .btn {
						margin-left: auto;
					}

					.btn {
						display: block;
						margin: 2rem auto 1.6rem;
					}
				}
			}
		}
	}
}
