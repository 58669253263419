.seo-footer-container {
	padding: 2.4rem 1.6rem 3.2rem;
}

.box-seo-footer {
	width: 100%;
	max-width: 87.6rem;
	margin: 0 auto;
}

.seo-footer-title {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: $font-size-base-rem;
	font-weight: $bold-font-weight;
	line-height: normal;
	cursor: pointer;

	&::first-letter {
		text-transform: capitalize;
	}

	&:after {
		content: "";
		width: 1.4rem;
		height: 1.4rem;
		margin-left: 0.8rem;
		background: url("/Content/App/dist/img/arrow-thin-grey-right.svg") right center no-repeat;
		transform: rotate(90deg);
		transition: transform linear 0.3s;
	}
}

.seo-footer-title.open {
	&:after {
		transform: rotate(-90deg);
		transition: transform linear 0.3s;
	}

	& + .seo-links-list {
		display: flex;
	}
}

.seo-footer-group {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	background: #FFFFFF;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
	padding: 2.4rem;
	border-radius: 1.2rem;
}

.seo-links-list {
	width: 100%;
	display: none;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 1.2rem;
	margin-top: 0.8rem;
}

.seo-links-list ul {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0;
	padding: 0;
}

.seo-links-list ul li {
	font-size: $font-size-base-rem;
	line-height: 1.43;
	list-style: none;
}

.seo-link, .seo-link a {
	color: $text-color;
	font-size: $font-size-base-rem;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
}

.seo-link {
	a {
		display: block;

		&::first-letter {
			text-transform: capitalize;
		}
	}
}

.seo-link:not(:last-of-type) {
	position: relative;
	margin-right: 1.6rem;

	&:after {
		position: absolute;
		content: "-";
		right: -1.1rem;
		bottom: 0;
		color: #a4a4a4;
	}
}

@media screen and (min-width: $screen-md-min) {
	.seo-footer-title {
		font-size: 1.8rem;
	}
}
