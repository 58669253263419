// Base styles
.btn {
    text-transform: uppercase;
    @include button-size(10px, 16px, $font-size-base - 1, $line-height-base, $btn-border-radius);

    &.wrap {
        white-space: normal;
    }
}

.btn {
    outline: none !important;
    transition: background-color 0.2s, color 0.2s;
}

.btn-primary {
	.pict-load {
		display: none;
		width: 2.2rem;
		height: 2.2rem;
	}

	&.load {
		background-color: $brand-primary-saturated;

		span.glyphicon-search,
		span.glyphicon-envelope,
		span.glyphicon-bell,
		span.glyphicon-earphone,
		span.btn-ok {
			display: none;
		}

		.pict-load {
			display: inline-block;
		}
	}

	&:hover {
		background-color: $brand-primary-saturated;
	}

	> a {
		color: #fff;
	}
}

.btn-success{
    border:1px solid $green;
    color:$green;
    background:white;
    &:hover{
        color:$green;
        background:white;
    }
}
