.pagination {
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    padding: 0;
	list-style-type: none;
    text-align: center;

	> li {
        display: inline-block;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 0.6rem;
        padding-top: 2px;
		text-align: center;
		// set radius too for the first & last childs
		&:first-child, &:last-child {
			> span, > a {
				border-radius: $btn-border-radius;
			}
		}
		// basic items
		> span, > a {
			position: initial;
			background-color: transparent;
			color: $text-color;
			height: 2.6rem;
			line-height: 2.2rem;
			margin-left: -0.1rem;
			min-width: 2.6rem;
			padding: 0;
			text-decoration: none;
			border: 0;
			font-size: $font-size-base;
		}
        
        &:not(.active) {
            cursor: pointer;
        }
        // Active items
		&.active {
			> span, > a, > span:hover, > a:hover {
				background-color: transparent;
				border: 1px solid $deepblue;
				border-radius: 50%;
				color: $deepblue;
			}

			&.with-page {
				display: none;
			}
		}
		// Custom next button
		&.pagination-next, &.pagination-prev {
            .pagination-arrow {
                background: url('/Content/App/dist/img/arrow-thin-grey-right.svg') center center no-repeat;
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                vertical-align: middle;
            }

            &:hover, &:focus {
                background-color: transparent;
                border-color: transparent;
                & > a {
                    background-color: transparent;
                    border-color: transparent;
                }

                .pagination-arrow {
                    background: url('/Content/App/dist/img/arrow-thin-blue-right.svg') center center no-repeat;
                }
            }
        }
		&.pagination-prev {
			.pagination-arrow {
				transform: rotate(180deg);
			}
		}
	}
	//hover will not affect buttons Prev and Next and Active
	> li:not(.pagination-prev):not(.pagination-next):not(.active) > span,
	> li:not(.pagination-prev):not(.pagination-next):not(.active) > a {
		&:hover, &:focus {
			background-color: $deepblue;
			color: $gray2-lighter;
			border-radius: 50%;
		}
	}
}
@media screen and (max-width: 379px) {
	.pagination {
		& > li.pagination-prev > a {
			margin-left: -0.4rem;
			margin-right: 0;
		}

		& > li.pagination-next > a {
			margin-right: 0;
		}

		& > li > span, > li > a {
			margin-right: 0.3rem;
		}
	}
}

@media screen and (min-width: $screen-sm-min) {
	.pagination > li > span, .pagination > li > a {
		margin-right: 1rem;
	}
}

@media screen and (max-width: 379px) {
	.pagination {
		& > li.pagination-prev > a {
			margin-left: -0.4rem;
			margin-right: 0;
		}

		& > li.pagination-next > a {
			margin-right: 0;
		}
	}
}

@media screen and (min-width: 380px) and (max-width: $screen-xs-max) {
	.pagination > li > span, .pagination > li > a {
		margin-right: 0.4rem;
	}
}

.sub-paging {
	.pagination {
		li {
			> span, > a {
				color: $gray-p;
			}
		}
	}
}