﻿// Component animations
.fade {
	@extend .default-transition;

	&.in {
		@extend .default-transition;
	}
}

.default-transition {
	@include transition(all 250ms ease);
}
