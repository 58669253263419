// Modals
.modal {
	overflow-y: auto;

	.title {
		font-size: 2.4rem;
		color: $brand-primary;
	}

	.close {
		margin-top: .32rem;
	}

	.modal-body {
		textarea {
			min-height: 1.3rem;
		}
	}
}

@media (min-width: $screen-md-min) {
  .modal-lg { width: $modal-lg; }
}
