// Base class
.tooltip {
	min-width: 15rem;

	&.in {
		opacity: 1;
	}

	&.top {
		padding: $tooltip-arrow-border-width 0;
	}

	&.right {
		padding: 0 $tooltip-arrow-border-width;
	}

	&.bottom {
		margin-left: -3rem;
		padding: $tooltip-arrow-border-width 0;
	}

	&.left {
		padding: 0 $tooltip-arrow-border-width;
	}
}

// Wrapper for the tooltip content
.tooltip-inner {
	border: $tooltip-border-width solid rgba(0, 0, 0, .5);
	-webkit-background-clip: padding-box; /* for Safari */
	background-clip: padding-box; /* for Firefox 4+, Opera, Chrome */
	border-radius: 2px, 2px;

	> div {
		font-size: 1.9rem;
		overflow: hidden;
		width: 100%;

		.price-left {
			float: left;
			white-space: nowrap;
		}

		.price-unit {
			color: $brand-primary;
			font-size: 1.3rem;
			float: right;
			padding-top: 0.3rem;
			white-space: nowrap;
		}
	}
}

// Arrows
.tooltip-arrow:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: -1;
}

.tooltip {
    &.top .tooltip-arrow {
        bottom: 2 * $tooltip-border-width;
        &:after {
            bottom: -2 * $tooltip-border-width;
            left: 50%;
            margin-left: -$tooltip-arrow-border-width;
            border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
			border-top-color: $tooltip-border-color;
        }
    }
    &.bottom .tooltip-arrow {
        top: 2 * $tooltip-border-width;
        left: 51%;
        margin-left: -$tooltip-arrow-width + 35;
        &:after {
            top: -2 * $tooltip-border-width;
            left: 50%;
            margin-left: -$tooltip-arrow-border-width;
            border-width: 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
			border-bottom-color: $tooltip-border-color;
        }
    }
    &.left .tooltip-arrow {
        right: 2 * $tooltip-border-width;
        &:after {
            right: -2 * $tooltip-border-width;
            top: 50%;
            margin-top: -$tooltip-arrow-border-width;
            border-width: $tooltip-arrow-border-width 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
			border-left-color: $tooltip-border-color;
        }
    }
    &.right .tooltip-arrow {
        left: 2 * $tooltip-border-width;
        &:after {
            left: -2 * $tooltip-border-width;
            top: 50%;
            margin-top: -$tooltip-arrow-border-width;
            border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
			border-right-color: $tooltip-border-color;
        }
    }
}
