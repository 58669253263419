// > FOOTER
#meta .container {
	width: 100%;
	max-width: 1312px;
	margin: 0 auto;
	padding: 0 1.6rem;
}

// >> Legende
#meta {
	padding-bottom: 0;
	padding-top: 1.3rem;

	p.legends {
		color: $gray;
		font-size: $font-size-tiny;
		line-height: 1.6rem;
		text-align: justify;
	}
}
// >> champ Input + bouton turquoise avec flèche blanche (via le footer on couvre tout le site)
@import "../common/components/agb-input-with-arrow";

// HACK : to hide pixel audience and pixel ads (height 1px x  width 1px) at the bottom of the pages
body ~ img, body > script ~ img, body ~ iframe + img  {
	display: none;
}
